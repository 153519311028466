import styled from 'styled-components';

export const GameRecommentItemWrapper = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

export const GameRecommentImg = styled.img`
  width: 100%;
  cursor: pointer;
  object-fit: contain;
  object-position: left bottom;
`;

export const GameRecommentContent = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const GameRecommentContentName = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  cursor: pointer;
`;

export const GameRecommentContentTags = styled.div`
  display: flex;
  gap: 4px;
`;

export const GameRecommentContentTag = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const GameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 64px;
  margin-top: 20px;
`;
