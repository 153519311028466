import { Button, Input } from 'antd';
import { ReactComponent as LoadMoreIconImg } from 'assets/icons/loadmore.svg';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const ProfileWrapper = styled.div`
  width: 100%;
  margin-bottom: 75px;
`;

export const ProfileContentWrapper = styled.div`
  gap: 48px;
  display: flex;
  flex-direction: column;

  margin: 75px 142px 0 142px;
`;

export const BackgroundWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const Background = styled.img`
  width: 100%;
  user-select: none;
`;

export const ProfileAvatarWrapper = styled.div`
  position: absolute;
  transform: translateY(50%);
  bottom: 0;
  left: 0;
  margin-left: 142px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const AvatarWrapper = styled.div`
  padding: 9.4px;
  gap: 23.5px;
  border-radius: 50%;
  background: var(--biga-blue);
`;

export const Avatar = styled.img`
  width: 75.2px;
  height: 75.2px;
  user-select: none;
  border-radius: 50%;
  cursor: pointer;
`;

export const UsernameWrapper = styled.div`
  padding: 8px 10px;
  gap: 10px;
  border-radius: 100px;
  border: 2px solid #0a3885;
  background: #fff;
`;

export const Username = styled(Text)`
  color: #0a3885;
  font-size: 14px;
  font-weight: 600;
`;

export const ProfileInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* gap: 142px; */

  @media (max-width: 1300px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }
`;

export const InfoLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const InfoRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 40px;
  gap: 2px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  width: 486px;
`;

export const InfoRightWrapperForScroll = styled.div<{
  isEmpty?: boolean;
}>`
  display: flex;
  flex-direction: column;
  padding: 0px 40px;
  gap: 2px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  width: 486px;

  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  height: ${props => (props.isEmpty ? '100%' : '400px')};
  max-height: 100%;
`;

export const InfoTitle = styled(Text)`
  font-size: 24px;
  font-weight: 600;

  @media (max-width: 1300px) {
    text-align: center;
  }
`;

export const InfoDetail = styled(Text)`
  width: 330px;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.6;

  @media (max-width: 1300px) {
    text-align: center;
  }
`;

export const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 486px;
  max-width: 100%;
  padding: 32px 0px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

export const InfoTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const UnderlineText = styled(Text)`
  font-size: 16px;
  font-weight: 600;
  border-bottom: 1px solid #fff;

  cursor: pointer;
  user-select: none;
`;

export const InfoTitleText = styled(Text)`
  font-size: 16px;
  font-weight: 500;
`;

export const InfoValueText = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  opacity: 0.6;
`;

export const WithdrawWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media (max-width: 1300px) {
    flex-direction: column;
    gap: 10px;
  }
`;

export const WithdrawText = styled(Text)`
  display: flex;
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  font-weight: 600;
`;

export const AddText = styled(Text)`
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(255, 255, 255, 0.04);

  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
`;

export const BIGATextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`;

export const BIGATitle = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  opacity: 0.6;
`;

export const BIGAValue = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;

export const BIGAInfoRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  @media (max-width: 1300px) {
    align-items: center;
    justify-content: center;
  }
`;

export const BIGAInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const BIGAInfoHeaderWrapper = styled.div`
  display: flex;
  width: 486px;
  padding: 32px 0px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: #878273;
`;

export const RevenueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const RevenueTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export const RevenueValue = styled(Text)`
  font-size: 32px;
  font-weight: 700;
`;

export const RevenueTitle = styled(Text)`
  font-size: 14px;
  font-weight: 500;
`;

export const RevenueLogo = styled.img`
  width: 24px;
  height: 24px;
`;

export const DeleteButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 1300px) {
    align-items: center;
    justify-content: center;
  }
`;

export const DeleteButton = styled(Text)`
  padding: 12px 24px;

  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(255, 255, 255, 0.08);
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
`;

export const CollapseWrapper = styled.div`
  display: flex;
  height: 205px;
  padding: 32px 16px;
  justify-content: center;
  align-items: center;
  background: #fff;
`;

export const CollapseContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 354px;
  max-width: 100%;
  gap: 16px;

  .ant-input-focused,
  .ant-input:focus {
    border-color: rgba(0, 0, 0, 0.16);
    box-shadow: none;
  }
  .ant-input:hover {
    border-color: rgba(0, 0, 0, 0.16);
  }
`;

export const CollapseHeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const CollapseTitleHeader = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
`;

export const CollapseCancelButton = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #000;

  cursor: pointer;
  user-select: none;
`;

export const CollapseInputWrapper = styled(Input)`
  max-width: 100%;
  padding: 16px;
  gap: 10px;
  border: 1px solid rgba(0, 0, 0, 0.16);
`;

export const CollapseSaveButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const CollapseSaveButton = styled(Button)`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
  padding: 12px 24px;
  background: var(--biga-blue);
  font-size: 14px;
  font-weight: 600;
  user-select: none;
  height: auto;
  outline: 0;
  border: none;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: var(--biga-blue);
    outline: 0;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: none;
    background: var(--biga-blue);
    outline: 0;
  }

  @media (max-width: 1300px) {
    width: 100%;
  }
`;

export const LoadMoreButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(255, 255, 255, 0.16);
  background: rgba(255, 255, 255, 0.04);
`;

export const LoadMoreContentWrapper = styled.div`
  display: flex;
  padding: 0px 24px;
  gap: 8px;
  margin: 16px 0;
  cursor: pointer;
`;

export const LoadMoreText = styled(Text)`
  /* color: var(--biga-blue, #0a3885); */
  color: #fff;
  font-size: 16px;
  font-weight: 600;
`;

export const LoadMoreIcon = styled(LoadMoreIconImg)`
  width: 18px;
  height: 18px;
  path {
    fill: #fff;
  }
`;
