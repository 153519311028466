import { WarningTwoTone } from '@ant-design/icons';
import closeicon from 'assets/vectors/logo-profile-close.svg';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';

import { DeleteAccount } from '.';
import {
  CloseIcon,
  CloseWrapper,
  ConfirmDeleteButtonWrapper,
  ConfirmDeleteNoButton,
  ConfirmDeleteYesButton,
  ModalConfirmDeleteTitle,
  ModalConfirmDeleteWrapper,
  ModalDeleteAccountWrapper,
  ModalDeleteContainer,
  ModalWrapper,
  NoteWrapper,
  WarningWrapper,
} from './styled';

export const ConfirmDelete = () => {
  const open = useModalIsOpen(ApplicationModal.PROFILE_CONFIRM_DELETE_ACCOUNT);
  const toggleModal = useToggleModal(
    ApplicationModal.PROFILE_CONFIRM_DELETE_ACCOUNT,
  );
  const handleCancel = () => {
    toggleModal();
  };

  const toggleConfirmDeleteModal = useToggleModal(
    ApplicationModal.PROFILE_DELETE_ACCOUNT,
  );
  const showConfirmDeleteModal = () => {
    toggleConfirmDeleteModal();
  };

  return (
    <ModalWrapper
      visible={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={null}
      closable={false}
      width="620px"
    >
      <ModalDeleteAccountWrapper>
        <CloseWrapper>
          <CloseIcon src={closeicon} onClick={handleCancel} />
        </CloseWrapper>
        <ModalDeleteContainer>
          <ModalConfirmDeleteWrapper>
            <ModalConfirmDeleteTitle>
              Delete Profile Confirmation
            </ModalConfirmDeleteTitle>

            <WarningWrapper>
              <WarningTwoTone twoToneColor="#fcd00d" />
              Warning!
              <WarningTwoTone twoToneColor="#fcd00d" />
            </WarningWrapper>

            <NoteWrapper>
              <li>
                Deleting your profile is permanent and cannot be reactivated.
              </li>
              <li>
                Once deleted, you will be disqualified from any future earnings
                or bonuses from our arcade.
              </li>
              <li>
                Please note: Your scores will remain visible. This is to ensure
                accurate recording based on total plays.
              </li>
            </NoteWrapper>

            <ConfirmDeleteButtonWrapper>
              <ConfirmDeleteYesButton onClick={showConfirmDeleteModal}>
                Yes, Delete My Profile
              </ConfirmDeleteYesButton>
              <ConfirmDeleteNoButton onClick={handleCancel}>
                No, Go Back
              </ConfirmDeleteNoButton>
            </ConfirmDeleteButtonWrapper>
          </ModalConfirmDeleteWrapper>
        </ModalDeleteContainer>
        <DeleteAccount />
      </ModalDeleteAccountWrapper>
    </ModalWrapper>
  );
};
