import { Modal } from 'antd';
import { ReactComponent as LogoClosed } from 'assets/vectors/logo-play-game-close.svg';
import { ReactComponent as LogoMinimized } from 'assets/vectors/logo-play-game-minimize.svg';
import styled from 'styled-components';

export const Text = styled.div`
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const ModalWrapper = styled(Modal)`
  & > .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    .ant-modal-body {
      padding: 0;
    }
  }
`;

export const ModalLevelMasterTableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .ant-table-tbody tr:nth-child(even) td {
    background-color: #f3f4f6;
  }

  .load-more-button {
    border-radius: 0 0 8px 8px;
  }
`;

export const LevelMasterTableHeaderWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseButton = styled(LogoClosed)`
  width: 24px;
  height: 24px;
  padding: 5px;
  background: #fff;
  cursor: pointer;
  user-select: none;
  margin-left: 2rem;
`;

export const MinimizeWrapper = styled.div`
  gap: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const MinimizeButton = styled(LogoMinimized)`
  width: 24px;
  height: 24px;
  padding: 5px;
  background: #fff;
`;

export const MinimizeText = styled(Text)`
  color: #000;
  font-size: 14px;
  font-weight: 500;
`;

export const LevelMasterTableBodyWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  padding: 32px 0;
  background: #f3f4f6;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 480px) {
    gap: 16px;
    flex-direction: column;
  }
`;

export const LevelMasterTableBodyItem = styled.div`
  width: 100%;
  max-width: 100%;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const LevelMasterTableBodyItemTitle = styled(Text)`
  color: #000;
  font-size: 14px;
  font-weight: 400;
`;

export const LevelMasterTableBodyItemValue = styled(Text)`
  color: #000;
  font-size: 24px;
  font-weight: 600;
`;

export const TableWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
