import bigaArcadeLogo from 'assets/vectors/BIGA.svg';
import { Link } from 'react-router-dom';

import { LandingJoinWaitListButton } from './JoinWaitListButton';
import {
  LandingFooterContentContactUs,
  LandingFooterContentPolicyLinksColumn,
  LandingFooterContentPolicyLinksWrapper,
  LandingFooterContentSocialLinksColumn,
  LandingFooterContentSocialLinksWrapper,
  LandingFooterContentWrapper,
  LandingFooterDividerWrapper,
  LandingFooterMailWrapper,
  LandingFooterSectionWrapper,
  LandingFooterWaitListJoinWrapper,
} from './styled';
import { WhitePaperButton } from './WhitePaperButton';

export const LandingFooterSection = () => {
  const termsLink = `${process.env.REACT_APP_HOST}/termsandconditions.pdf`;
  const policyLink = `${process.env.REACT_APP_HOST}/privacy-policy.pdf`;

  return (
    <>
      <LandingFooterDividerWrapper>
        <div></div>
      </LandingFooterDividerWrapper>

      <LandingFooterSectionWrapper>
        <img src={bigaArcadeLogo} alt="biga-arcade" />
        <LandingFooterContentWrapper>
          <LandingFooterContentContactUs>
            <div>
              <p>Our mission is to give one million gamers,</p>
              <p>a reliable $1000 a month income</p>
            </div>
            <LandingFooterMailWrapper>
              <div>Contact Us</div>
              <Link to="mailto:hello@bigarcade.org" className="mail-to-contact">
                hello@bigarcade.org
              </Link>
            </LandingFooterMailWrapper>
          </LandingFooterContentContactUs>

          <LandingFooterContentSocialLinksWrapper>
            <LandingFooterContentSocialLinksColumn>
              <Link
                className="active-link"
                to="https://twitter.com/bigaarcade"
                target="_blank"
              >
                Twitter
              </Link>
              <Link
                className="blur-link"
                to="https://discord.gg/nGcGdS7tT3"
                target="_blank"
              >
                Discord
              </Link>
            </LandingFooterContentSocialLinksColumn>
            <LandingFooterContentSocialLinksColumn>
              <Link
                className="blur-link"
                to="https://www.linkedin.com/company/bigaarcade/"
                target="_blank"
              >
                Linkedin
              </Link>
              <Link
                className="blur-link"
                to="https://www.instagram.com/bigaarcade/"
                target="_blank"
              >
                Instagram
              </Link>
            </LandingFooterContentSocialLinksColumn>
          </LandingFooterContentSocialLinksWrapper>

          <LandingFooterWaitListJoinWrapper className="footer-wait-list-join-wrapper-inside">
            <LandingJoinWaitListButton />
            <WhitePaperButton />
          </LandingFooterWaitListJoinWrapper>
        </LandingFooterContentWrapper>
        <LandingFooterWaitListJoinWrapper className="footer-wait-list-join-wrapper-outside">
          <LandingJoinWaitListButton />
          <WhitePaperButton />
        </LandingFooterWaitListJoinWrapper>
        <LandingFooterContentPolicyLinksWrapper>
          <LandingFooterContentPolicyLinksColumn>
            <Link className="blur-link" to={termsLink} target="_blank">
              Terms and Conditions
            </Link>
            <Link className="blur-link" to={policyLink} target="_blank">
              Privacy Policy
            </Link>
          </LandingFooterContentPolicyLinksColumn>
        </LandingFooterContentPolicyLinksWrapper>
      </LandingFooterSectionWrapper>

      {/* <LandingFooterBottomDiv></LandingFooterBottomDiv> */}
    </>
  );
};
