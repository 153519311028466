import { Modal, Upload } from 'antd';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const ModalWrapper = styled(Modal)`
  & > .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    .ant-modal-body {
      padding: 0;
    }
  }
`;

export const ModalEditAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 448px; */
  gap: 20px;
`;

export const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  user-select: none;
`;

export const CloseIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const ModalEditWrapper = styled.div`
  display: flex;
  /* width: 448px; */
  padding-top: 55px;
  flex-direction: column;
  gap: 55px;
  background: #fff;
`;

export const ModalCropAvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

export const CropWrapper = styled.div`
  width: 211px;
  height: 211px;
  border: 1px dashed #000;
`;

export const UploadProfileDragger = styled(Upload.Dragger)`
  &.ant-upload.ant-upload-drag {
    background: #fff;
    border: none;
  }

  &.ant-upload.ant-upload-drag .ant-upload {
    padding: 0;
  }
`;

export const CropText = styled(Text)`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  opacity: 0.4;
`;

export const ModalAvatarButtonWrapper = styled.div`
  display: flex;
  padding: 24px 32px;
  justify-content: space-between;
  background: var(--light-gray-6, #f2f2f7);
  gap: 32px;
`;

export const ClearButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.16);
  flex: 1;
  text-align: center;
`;

export const ClearButton = styled(Text)`
  padding: 12px 0;
  gap: 10px;
  cursor: pointer;
  user-select: none;

  color: #000;
  font-size: 18px;
  font-weight: 500;
  opacity: 0.4;
`;

export const SaveImageButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--biga-blue);
  flex: 1;
`;

export const SaveImageButton = styled(Text)`
  padding: 12px 0;
  display: inline-flex;
  gap: 5px;
  cursor: pointer;
  user-select: none;

  font-size: 18px;
  font-weight: 600;
  text-align: center;
`;

export const ModalEditContainer = styled.div`
  background-color: #fff;
`;
