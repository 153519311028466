import { Input } from 'antd';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const CashierRightContentThirdBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  & > .ant-input {
    padding: 0;
  }

  & > .ant-input::-webkit-outer-spin-button,
  .ant-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  & > .ant-input::placeholder {
    color: #000;
    font-family: Neue Haas Grotesk Display Pro;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    opacity: 0.24;
  }
`;

export const ThirdBlockTitle = styled(Text)`
  color: #000;
  opacity: 0.6;
  font-size: 16px;
  font-weight: 500;
`;

export const ThirdBlockValue = styled(Input)`
  color: #000;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;

  .ant-input-affix-wrapper,
  .ant-input {
    width: 200px;
    text-align: right;

    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;

    padding: 0;
    color: #000;
    font-size: 35px;
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;

    &:focus,
    &:hover,
    &.ant-input-affix-wrapper:focus,
    &.ant-input-affix-wrapper:hover {
      border: none;
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      color: #000;
      opacity: 0.24;
    }
    &:-ms-input-placeholder {
      color: #000;
      opacity: 0.24;
    }
    &::-ms-input-placeholder {
      color: #000;
      opacity: 0.24;
    }
    .anticon.anticon-close-circle {
      color: #000;
      opacity: 0.24;
    }
  }

  .ant-input.ant-input-borderless {
    padding-right: 16px;
  }

  .ant-input-group-addon {
    width: 200px;
    padding: 0;
    color: #000;
    border: none;
    font-size: 35px;
    text-align: left;
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    background-color: transparent;
    font-family: Neue Haas Grotesk Display Pro;
  }
`;

export const ThirdBlockUnit = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
`;

export const ThirdBlockErrorText = styled(ThirdBlockUnit)`
  color: #ff3b3b;
`;

export const CashierRightContentFourthBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

export const FourthBlockContentWrapper = styled.div<{
  withdraw?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => (props.withdraw ? '16px' : '24px')};
  width: 100%;
`;

export const FourthBlockBalanceWrapper = styled.div`
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border: 1px solid rgba(0, 0, 0, 0.08);
`;

export const FourthBlockBalanceUnit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f7;
  cursor: pointer;
  user-select: none;
  padding: 8px 8px 8px 16px;
`;

export const FourthBlockBalanceUnitText = styled.div`
  text-align: center;
  color: #000;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const FourthBlockButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const FourthBlockButton = styled.div<{ disabled?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => (props.disabled ? '#b3b3b3' : '#fff')};
  background: ${props => (props.disabled ? 'rgba(0, 0, 0, 0.08)' : '#0a3885')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const FourthBlockButtonText = styled.div`
  padding: 16px;
  user-select: none;
  width: 100%;

  text-align: center;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const FourthBlockCreditWrapper = styled.div`
  padding: 22.5px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: #f2f2f7;
`;

export const FourthBlockCreditTitle = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
`;

export const FourthBlockCreditValueWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const FourthBlockCreditValue = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
`;

export const FourthBlockCreditAll = styled(Text)`
  cursor: pointer;
  user-select: none;

  color: var(--brand-primary, #0a3885);
  font-size: 16px;
  font-weight: 600;
`;
