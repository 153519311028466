import { Col } from 'antd';
import styled from 'styled-components';

export const GamesWrapper = styled.div`
  margin: 36px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TitleStyled = styled.div`
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  font-style: normal;
  line-height: normal;
  text-transform: uppercase;
  font-family: Changeling Neo;
`;

export const FilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
`;

export const FilterText = styled.div`
  color: #fff;
  opacity: 0.4;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const DropdownTitleWrapper = styled.div`
  gap: 4px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.24);
`;

export const DropdownTitle = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const DropdownIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const MenuWrapper = styled.div`
  display: flex;
  width: 220px;
  padding: 16px 0px;
  flex-direction: column;
  align-items: flex-start;
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.16);
`;

export const MenuItemWrapper = styled.div`
  gap: 10px;
  padding: 14px 16px;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;

  color: #000;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;

  &:hover {
    color: #fff;
    background: #878273;
  }
`;

export const GameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 64px;
  margin-top: 20px;
`;

export const GameStyledCol = styled(Col)`
  @media (max-width: 680px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
`;
