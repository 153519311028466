import { Button, Input } from 'antd';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const CashierRightContentSecondBlock = styled(Text)`
  width: 100%;
  color: #0a3885;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
`;

export const CashierRightContentThirdBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  & > .ant-input {
    padding: 0;
  }

  & > .ant-input::-webkit-outer-spin-button,
  .ant-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  & > .ant-input::placeholder {
    color: #000;
    font-family: Neue Haas Grotesk Display Pro;
    font-size: 35px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
    opacity: 0.24;
  }
`;

export const ThirdBlockTitle = styled(Text)`
  color: #000;
  opacity: 0.6;
  font-size: 16px;
  font-weight: 500;
`;

export const ThirdBlockValue = styled(Input)`
  color: #000;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;

  .ant-input-affix-wrapper,
  .ant-input {
    width: 200px;
    text-align: right;

    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;

    padding: 0;
    color: #000;
    font-size: 35px;
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;

    &:focus,
    &:hover,
    &.ant-input-affix-wrapper:focus,
    &.ant-input-affix-wrapper:hover {
      border: none;
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      color: #000;
      opacity: 0.24;
    }
    &:-ms-input-placeholder {
      color: #000;
      opacity: 0.24;
    }
    &::-ms-input-placeholder {
      color: #000;
      opacity: 0.24;
    }
    .anticon.anticon-close-circle {
      color: #000;
      opacity: 0.24;
    }
  }

  .ant-input.ant-input-borderless {
    padding-right: 16px;
  }

  .ant-input-group-addon {
    width: 200px;
    padding: 0;
    color: #000;
    border: none;
    font-size: 35px;
    text-align: left;
    font-weight: 700;
    font-style: normal;
    line-height: normal;
    background-color: transparent;
    font-family: Neue Haas Grotesk Display Pro;
  }
`;

export const ThirdBlockUnit = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
`;

export const ThirdBlockErrorText = styled(ThirdBlockUnit)`
  color: #ff3b3b;
`;

export const CashierRightContentFourthBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

export const FourthBlockContentWrapper = styled.div<{
  withdraw?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${props => (props.withdraw ? '16px' : '24px')};
  width: 100%;
`;

export const FourthBlockSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`;

export const FourthBlockSelectTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  align-self: stretch;
`;

export const FourthBlockSelectButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const FourthBlockSelectButton = styled.div<{ disabled?: boolean }>`
  border: 1px solid rgba(0, 0, 0, 0.08);
  width: 57px;
  &:hover,
  &:active,
  &:focus,
  &.number-active {
    background: #f2f2f7;
  }
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

export const FourthBlockSelectButtonText = styled(Text)`
  padding: 16px 0;
  cursor: pointer;
  user-select: none;

  text-align: center;
  color: #000;
  font-size: 16px;
  font-weight: 500;
`;

export const Line = styled.div`
  flex: 1;
  height: 1px;
  background: #000;
`;

export const Title = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
`;

export const FourthBlockBalanceWrapper = styled.div`
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border: 1px solid rgba(0, 0, 0, 0.08);
`;

export const FourthBlockBalanceUnit = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f7;
  cursor: pointer;
  user-select: none;
  padding: 8px 8px 8px 16px;

  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

export const FourthBlockBalanceUnitText = styled.div`
  text-align: center;
  color: #000;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const FourthBlockButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
`;

export const FourthBlockButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  outline: 0;
  width: 100%;
  border: none;
  height: auto;
  background: #0a3885;

  color: #fff;
  font-weight: 600;
  font-size: 18px;

  padding: 16px 0;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: var(--biga-blue);
    outline: 0;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: none;
    background: var(--biga-blue);
    outline: 0;
  }
`;

export const FourthBlockButtonGemini = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(11, 56, 133, 0.24);
`;

export const Gemini = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const FourthBlockButtonTextGemini = styled.div<{ disabled?: boolean }>`
  padding: 16px;
  user-select: none;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 100%;
  opacity: ${props => (props.disabled ? '0.4' : '1')};

  text-align: center;
  color: #000;
  text-align: center;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const FourthBlockButtonPayWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  /* align-self: stretch; */
  width: 100%;

  & > .ant-tooltip-disabled-compatible-wrapper {
    flex: 1;
  }
`;

export const FourthBlockButtonPay = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;

  outline: 0;
  width: 100%;
  border: 1px solid rgba(11, 56, 133, 0.24);
  height: auto;
  background: #fff;

  color: #fff;
  font-weight: 600;
  font-size: 18px;

  padding: 16px 0;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: #fff;
    outline: 0;
    border: 1px solid rgba(11, 56, 133, 0.24);
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: 1px solid rgba(11, 56, 133, 0.24);
    background: #fff;
    outline: 0;
  }
  &.ant-btn[disabled],
  &.ant-btn[disabled]:active,
  &.ant-btn[disabled]:focus,
  &.ant-btn[disabled]:hover {
    opacity: 0.2;
  }
`;

export const LinePay = styled.div`
  height: 1px;
  flex: 1;
  background: rgba(0, 0, 0, 0.16);
`;

export const TitlePay = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
`;
