import { Col, Form, Row, Tooltip } from 'antd';
import defaultAvatarImg from 'assets/images/default-user-profile.png';
import { ActionButton } from 'components/Button/styled';
import { PlayerRoles } from 'constants/enum/PlayerRole';
import { formatUnits } from 'ethers/lib/utils';
import { useGetGameInfo } from 'hooks/games/useGetGameInfo';
import { useGetPlayer } from 'hooks/player/useGetPlayer';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import { useAppSelector } from 'redux/store';
import { truncateText } from 'utils/helper';

import {
  DividerStyled,
  GroupTitleStyled,
  InputWrapper,
  LabelStyled,
} from '../IntegrateGame/styled';
import { StyledForm, ThemedModal } from '../styled';
import {
  AvatarImg,
  FormItemInput,
  FormItemWrapper,
  HeaderContainer,
  HeaderTitle,
  TextField,
} from './styled';

export interface IGameDetails {
  gameId?: string;
  refresh?: boolean;
}

export const GameDetails = ({ gameId, refresh }: IGameDetails) => {
  const isOpen = useModalIsOpen(ApplicationModal.GAME_DETAILS);
  const reviewIntegrateToggle = useToggleModal(ApplicationModal.GAME_DETAILS);
  const configLevelFeeToggle = useToggleModal(
    ApplicationModal.CONFIG_LEVEL_FEE,
  );
  const updateGameToggle = useToggleModal(ApplicationModal.UPDATE_GAME);
  const [form] = Form.useForm();

  const { gameInfo } = useGetGameInfo(gameId, refresh);

  const onCloseModal = () => {
    reviewIntegrateToggle();
    form.resetFields();
  };

  const modalHeader = (
    <HeaderContainer>
      <HeaderTitle>{gameInfo?.name || `Game ${gameInfo?.gameId}`}</HeaderTitle>
    </HeaderContainer>
  );

  const { loading: playerLoading } = useGetPlayer(null, false);
  const { playerInfo } = useAppSelector(state => state.player);

  return (
    <>
      <ThemedModal
        title={modalHeader}
        open={isOpen}
        onCancel={onCloseModal}
        footer={false}
        width={870}
      >
        <StyledForm
          autoComplete="off"
          layout="vertical"
          labelcolor="black"
          form={form}
        >
          <FormItemWrapper>
            <GroupTitleStyled>Game Info</GroupTitleStyled>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <FormItemInput className="mt-4" name={['path']}>
                  <InputWrapper>
                    <LabelStyled param="125px">Game path</LabelStyled>
                    <TextField>{gameInfo?.slug}</TextField>
                  </InputWrapper>
                </FormItemInput>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <FormItemInput className="mt-4" name={['background-image-url']}>
                  <InputWrapper>
                    <LabelStyled param="175px">Background URL</LabelStyled>
                    <Tooltip
                      color="#23c879"
                      placement="top"
                      title={gameInfo?.backgroundImgUrl}
                    >
                      <TextField>{gameInfo?.backgroundImgUrl}</TextField>
                    </Tooltip>
                  </InputWrapper>
                </FormItemInput>
              </Col>
              <Col xs={24}>
                <FormItemInput className="mt-4" name={['description']}>
                  <InputWrapper>
                    <LabelStyled>Description</LabelStyled>
                    <Tooltip
                      color="#23c879"
                      placement="top"
                      title={
                        gameInfo?.description && (
                          <div>
                            {JSON.parse(gameInfo?.description)
                              ?.split('\n')
                              ?.map((value, key) => (
                                <div key={key}>{value}</div>
                              ))}
                          </div>
                        )
                      }
                    >
                      <TextField>
                        {gameInfo?.description &&
                          JSON.parse(gameInfo?.description)}
                      </TextField>
                    </Tooltip>
                  </InputWrapper>
                </FormItemInput>
                <FormItemInput className="mt-4" name={['tags']}>
                  <InputWrapper>
                    <LabelStyled>Scoring model</LabelStyled>
                    <Tooltip
                      color="#23c879"
                      placement="top"
                      title={gameInfo?.scoringModel
                        ?.replace(/([A-Z])/g, ' $1')
                        ?.slice(1)}
                    >
                      <TextField>
                        {gameInfo?.scoringModel
                          ?.replace(/([A-Z])/g, ' $1')
                          ?.slice(1)}
                      </TextField>
                    </Tooltip>
                  </InputWrapper>
                </FormItemInput>
                <FormItemInput className="mt-4" name={['tags']}>
                  <InputWrapper>
                    <LabelStyled>Tags</LabelStyled>
                    <Tooltip
                      color="#23c879"
                      placement="top"
                      title={gameInfo?.tags?.join(', ')}
                    >
                      <TextField>{gameInfo?.tags?.join(', ')}</TextField>
                    </Tooltip>
                  </InputWrapper>
                </FormItemInput>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <FormItemInput className="mt-4" name={['min-level']}>
                  <InputWrapper>
                    <LabelStyled param="125px">Min level</LabelStyled>
                    <TextField>{gameInfo?.minLevel}</TextField>
                  </InputWrapper>
                </FormItemInput>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <FormItemInput className="mt-4" name={['max-level']}>
                  <InputWrapper>
                    <LabelStyled param="125px">Max level</LabelStyled>
                    <TextField>{gameInfo?.maxLevel}</TextField>
                  </InputWrapper>
                </FormItemInput>
              </Col>
            </Row>
            <DividerStyled />
            <GroupTitleStyled>Game Release</GroupTitleStyled>
            <Row gutter={8}>
              <Col xs={24}>
                <FormItemInput className="mt-4" name={['tags']}>
                  <InputWrapper>
                    <LabelStyled>Current game version</LabelStyled>
                    <Tooltip
                      color="#23c879"
                      placement="top"
                      title={gameInfo?.activeVersion}
                    >
                      <TextField>{gameInfo?.activeVersion}</TextField>
                    </Tooltip>
                  </InputWrapper>
                </FormItemInput>
              </Col>
            </Row>
            <DividerStyled />
            <GroupTitleStyled>Distribution Config</GroupTitleStyled>
            <Row gutter={8}>
              <Col xs={24} sm={24} md={24} lg={14}>
                <FormItemInput className="mt-4" name={['token']}>
                  <InputWrapper>
                    <LabelStyled param="125px">Tokens used</LabelStyled>
                    <Tooltip
                      color="#23c879"
                      placement="top"
                      title={gameInfo?.tokenAddress}
                    >
                      <TextField>
                        {truncateText(gameInfo?.tokenAddress, 18, 4)}
                      </TextField>
                    </Tooltip>
                  </InputWrapper>
                </FormItemInput>
                <FormItemInput className="mt-4" name={['token-symbol']}>
                  <InputWrapper>
                    <LabelStyled param="125px">Token name</LabelStyled>
                    <Tooltip
                      color="#23c879"
                      placement="top"
                      title={gameInfo?.tokenInfo?.name}
                    >
                      <TextField>
                        {truncateText(gameInfo?.tokenInfo?.name, 30, 0)}
                      </TextField>
                    </Tooltip>
                  </InputWrapper>
                </FormItemInput>
                {gameInfo?.adminInfo && (
                  <FormItemInput className="mt-4" name={['admin']}>
                    <InputWrapper>
                      <LabelStyled param="125px">Admin</LabelStyled>
                      <Tooltip
                        color="#23c879"
                        placement="top"
                        title={gameInfo?.adminInfo?.username}
                      >
                        <TextField>
                          {/* {truncateText(gameInfo?.adminInfo?.username, 18, 4)} */}
                          <AvatarImg
                            src={
                              gameInfo?.adminInfo?.avatarURL || defaultAvatarImg
                            }
                          />
                          {gameInfo?.adminInfo?.username}
                        </TextField>
                      </Tooltip>
                    </InputWrapper>
                  </FormItemInput>
                )}
                {gameInfo?.providerInfo && (
                  <FormItemInput className="mt-4" name={['provider']}>
                    <InputWrapper>
                      <LabelStyled param="125px">Provider</LabelStyled>
                      <Tooltip
                        color="#23c879"
                        placement="top"
                        title={gameInfo?.adminInfo?.username}
                      >
                        <TextField>
                          {/* {truncateText(
                            gameInfo?.providerInfo?.username,
                            18,
                            4,
                          )} */}
                          <AvatarImg
                            src={
                              gameInfo?.providerInfo?.avatarURL ||
                              defaultAvatarImg
                            }
                          />
                          {gameInfo?.providerInfo?.username}
                        </TextField>
                      </Tooltip>
                    </InputWrapper>
                  </FormItemInput>
                )}
                {gameInfo?.treasuryInfo && (
                  <FormItemInput className="mt-4" name={['treasury']}>
                    <InputWrapper>
                      <LabelStyled param="125px">Treasury</LabelStyled>
                      <Tooltip
                        color="#23c879"
                        placement="top"
                        title={gameInfo?.treasuryInfo?.username}
                      >
                        <TextField>
                          {/* {truncateText(
                            gameInfo?.treasuryInfo?.username,
                            18,
                            4,
                          )} */}
                          <AvatarImg
                            src={
                              gameInfo?.treasuryInfo?.avatarURL ||
                              defaultAvatarImg
                            }
                          />
                          {gameInfo?.treasuryInfo?.username}
                        </TextField>
                      </Tooltip>
                    </InputWrapper>
                  </FormItemInput>
                )}
              </Col>

              <Col xs={24} sm={24} md={24} lg={10}>
                <FormItemInput className="mt-4" name={['default-fee']}>
                  <InputWrapper>
                    <LabelStyled>Default entry fee</LabelStyled>
                    <Tooltip
                      color="#23c879"
                      placement="top"
                      title={
                        gameInfo?.defaultEntryFee &&
                        `${
                          formatUnits(
                            gameInfo.defaultEntryFee,
                            gameInfo?.tokenInfo?.decimals,
                          ) ?? 0
                        } ${gameInfo.tokenInfo?.symbol}`
                      }
                    >
                      <TextField>
                        {gameInfo?.defaultEntryFee &&
                          `${
                            formatUnits(
                              gameInfo.defaultEntryFee,
                              gameInfo.tokenInfo?.decimals,
                            ) ?? 0
                          } ${gameInfo.tokenInfo?.symbol}`}
                      </TextField>
                    </Tooltip>
                  </InputWrapper>
                </FormItemInput>
                <FormItemInput className="mt-4" name={['level-owner-profit']}>
                  <InputWrapper>
                    <LabelStyled>Level owner profit</LabelStyled>
                    <TextField>{gameInfo?.levelOwnerFeeRate / 100}%</TextField>
                  </InputWrapper>
                </FormItemInput>
                {gameInfo?.treasuryFeeRate && (
                  <FormItemInput className="mt-4" name={['treasury-profit']}>
                    <InputWrapper>
                      <LabelStyled>Third-party profit</LabelStyled>
                      <TextField>{gameInfo?.treasuryFeeRate / 100}%</TextField>
                    </InputWrapper>
                  </FormItemInput>
                )}
                {gameInfo?.providerFeeRate && (
                  <FormItemInput className="mt-4" name={['provider-profit']}>
                    <InputWrapper>
                      <LabelStyled>Provider profit</LabelStyled>
                      <TextField>{gameInfo?.providerFeeRate / 100}%</TextField>
                    </InputWrapper>
                  </FormItemInput>
                )}
                {gameInfo?.affiliateFeeRate && (
                  <FormItemInput className="mt-4" name={['affiliate-profit']}>
                    <InputWrapper>
                      <LabelStyled>Affiliate profit</LabelStyled>
                      <TextField>{gameInfo?.affiliateFeeRate / 100}%</TextField>
                    </InputWrapper>
                  </FormItemInput>
                )}
                <FormItemInput className="mt-4" name={['platform-profit']}>
                  <InputWrapper>
                    <LabelStyled>Platform profit</LabelStyled>
                    <TextField>{gameInfo?.platformFeeRate / 100}%</TextField>
                  </InputWrapper>
                </FormItemInput>
              </Col>
            </Row>
          </FormItemWrapper>
        </StyledForm>
        {(gameInfo?.adminInfo?.username === playerInfo?.username ||
          (!gameInfo?.isExternal &&
            (playerInfo?.roles?.includes(PlayerRoles.SuperAdmin) ||
              playerInfo?.roles?.includes(PlayerRoles.Admin)))) && (
          <Row style={{ justifyContent: 'space-evenly' }}>
            <Col>
              <ActionButton
                dark={'true'}
                onClick={() => configLevelFeeToggle()}
              >
                Configure level
              </ActionButton>
            </Col>

            <Col>
              <ActionButton dark={'true'} onClick={() => updateGameToggle()}>
                Update game
              </ActionButton>
            </Col>
          </Row>
        )}
      </ThemedModal>
    </>
  );
};
