import letsTalkImg from 'assets/vectors/lets-talk.svg';

import {
  LandingDeveloperSectionWrapper,
  LandingLetsTalkButton,
  LandingLetsTalkButtonWrapper,
} from './styled';

export const LandingDeveloperSection = () => {
  return (
    <LandingDeveloperSectionWrapper>
      <div className="lets-talk-title">Are you a Game Developer?</div>
      <div className="lets-talk-description">
        BIGA can help you monetize your existing or brand new game instantly,
        without clunky advertising or big upfront expenses.
      </div>
      <LandingLetsTalkButtonWrapper id="XbXfic2M">
        <LandingLetsTalkButton>
          <img src={letsTalkImg} alt="lets-talk" />
          <span>Let’s Talk</span>
        </LandingLetsTalkButton>
      </LandingLetsTalkButtonWrapper>
    </LandingDeveloperSectionWrapper>
  );
};
