import bigaLogo from 'assets/vectors/logo-biga-arcade.svg';
import { RegisterCongratulations } from 'components/Register/Congratulation';
import { FirstStep } from 'components/Register/FirstStep';
import { RegisterForm } from 'components/Register/RegisterForm';
import { RegisterSetupProfile } from 'components/Register/SetUpProfile';
import { RegisterVerifyCode } from 'components/Register/VerifyCode';
import { PlayerStatus } from 'constants/enum/PlayerStatus';
import { RegisterStep } from 'constants/enum/RegisterStep';
import { TokenType } from 'constants/enum/TokenType';
import { PitRouter } from 'constants/routers';
import { useGetPlayer } from 'hooks/player/useGetPlayer';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import { useLogOut } from 'hooks/useLogOut';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { loginUser } from 'redux/slices/player';
import { loginOAuth } from 'services/api';
import { LocalhostStorage } from 'utils/sessionStorage';

import { Logo, RegisterWrapper, StepWrapper } from './styled';

export const SignUpAccount = ({
  defaultStep,
}: {
  defaultStep: RegisterStep;
}) => {
  const [currentStep, setCurrentStep] = useState<RegisterStep>(defaultStep);
  const [refreshCountDown, setRefreshCountDown] = useState<boolean>(false);
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const { account } = useActiveWeb3React();
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    LocalhostStorage.set('path', pathname);
  }, [pathname]);
  const navigate = useNavigate();
  const [params] = useState({
    walletAddress: account,
  });
  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get('code');
  const state = queryParameters.get('state');

  useEffect(() => {
    void (async () => {
      if (code && state) {
        const responseData = await loginOAuth({
          code,
          state,
        });
        if (responseData?.success) {
          LocalhostStorage.set(
            'accessToken',
            responseData?.payload?.data?.accessToken,
          );
          LocalhostStorage.set(
            'refreshToken',
            responseData?.payload?.data?.refreshToken,
          );
          LocalhostStorage.set('tokenType', TokenType.CARV);

          dispatch(loginUser(responseData?.payload?.data));

          if (
            responseData?.payload?.data?.status === PlayerStatus.UploadedProfile
          ) {
            navigate(PitRouter.GAME_LIST);
          }
          if (
            responseData?.payload?.data?.status === PlayerStatus.VerifiedOTP
          ) {
            setCurrentStep(RegisterStep.setupProfile);
          }
        } else {
          toast.error(responseData?.message || 'Login failed');
        }
      }
    })();
  }, [code, navigate, state]);

  const { playerInfo } = useGetPlayer(params, refresh);

  useLogOut({
    onRefresh: () => {
      setRefresh(!refresh);
    },
  });

  useEffect(() => {
    void (async () => {
      if (!code || !state) {
        // if (!playerInfo) {
        //   setCurrentStep(RegisterStep.welcome);
        // }

        if (playerInfo?.status === PlayerStatus.WaitToVerifyOTP) {
          setCurrentStep(RegisterStep.verifyCode);
        }
        if (playerInfo?.status === PlayerStatus.VerifiedOTP) {
          setCurrentStep(RegisterStep.setupProfile);
        }
        if (playerInfo?.status === PlayerStatus.UploadedProfile) {
          // setCurrentStep(RegisterStep.congratulation);
          navigate(PitRouter.GAME_LIST);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerInfo]);
  const [emailAddress, setEmailAddress] = useState<string>('');

  const stepElement = useMemo(() => {
    switch (currentStep) {
      case RegisterStep.welcome:
        return (
          <FirstStep
            onSignUp={() => {
              setCurrentStep(RegisterStep.enterDetail);
            }}
          />
        );
      case RegisterStep.enterDetail:
        return (
          <RegisterForm
            onBack={() => {
              setCurrentStep(RegisterStep.welcome);
            }}
            onContinue={() => {
              // setRefresh(!refresh);
              setCurrentStep(RegisterStep.verifyCode);
            }}
            setWaitToVerifyEmailAddress={setEmailAddress}
          />
        );
      case RegisterStep.verifyCode:
        return (
          <RegisterVerifyCode
            onBack={() => {
              setCurrentStep(RegisterStep.enterDetail);
            }}
            refreshCountDown={refreshCountDown}
            onResend={() => {
              setRefreshCountDown(!refreshCountDown);
            }}
            onContinue={() => {
              setRefresh(!refresh);
              setCurrentStep(RegisterStep.setupProfile);
            }}
            waitToVerifyEmailAddress={emailAddress}
          />
        );
      case RegisterStep.setupProfile:
        return (
          <RegisterSetupProfile
            onContinue={() => {
              setCurrentStep(RegisterStep.congratulation);
            }}
          />
        );
      case RegisterStep.congratulation:
        return <RegisterCongratulations />;

      default:
        break;
    }
  }, [currentStep, refreshCountDown, refresh, emailAddress]);

  return (
    <RegisterWrapper>
      <Logo
        src={bigaLogo}
        alt="logo"
        onClick={() => {
          navigate(PitRouter.HOME);
        }}
      />
      <StepWrapper>{stepElement}</StepWrapper>
    </RegisterWrapper>
  );
};
