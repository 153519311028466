import { Purchase } from 'components/Cashier/Purchase';
import { WithDraw } from 'components/Cashier/Withdraw';
import { useState } from 'react';
import HomepageTemplate from 'template/Homepage';

import {
  CashierLeftContent,
  CashierMainWrapper,
  CashierRightContent,
  CashierRightContentFirstBlock,
  CashierWrapper,
  FirstBlockButton,
  FirstBlockButtonWrapper,
  LeftContentBody,
  LeftContentBodyItem,
  LeftContentBodyItemContent,
  LeftContentBodyItemContentWrapper,
  LeftContentBodyItemNumberWrapper,
  LeftContentBodyItemTitle,
  LeftContentHeader,
} from './styled';

export const Cashier = () => {
  const [activeButton, setActiveButton] = useState('Purchase');
  const [disabled, setDisabled] = useState(false);

  const handleButtonClick = buttonName => {
    setActiveButton(buttonName);
  };

  return (
    <HomepageTemplate>
      <CashierWrapper>
        <CashierMainWrapper>
          <CashierLeftContent>
            {activeButton === 'Purchase' ? (
              <LeftContentHeader>
                Please follow these steps to purchase
              </LeftContentHeader>
            ) : (
              <LeftContentHeader>
                Please follow these steps for withdrawal
              </LeftContentHeader>
            )}
            <LeftContentBody>
              <LeftContentBodyItem>
                <LeftContentBodyItemNumberWrapper>
                  01
                </LeftContentBodyItemNumberWrapper>
                <LeftContentBodyItemContentWrapper>
                  <LeftContentBodyItemTitle>
                    Select Amount
                  </LeftContentBodyItemTitle>
                  <LeftContentBodyItemContent>
                    Please input the desired amount of BIGA credits and select
                    desired cryptocurrency
                  </LeftContentBodyItemContent>
                </LeftContentBodyItemContentWrapper>
              </LeftContentBodyItem>
              <LeftContentBodyItem>
                <LeftContentBodyItemNumberWrapper>
                  02
                </LeftContentBodyItemNumberWrapper>
                <LeftContentBodyItemContentWrapper>
                  <LeftContentBodyItemTitle>
                    Review Details
                  </LeftContentBodyItemTitle>
                  <LeftContentBodyItemContent>
                    Review the desired amount details with transactions fees.
                  </LeftContentBodyItemContent>
                </LeftContentBodyItemContentWrapper>
              </LeftContentBodyItem>
              <LeftContentBodyItem>
                <LeftContentBodyItemNumberWrapper>
                  03
                </LeftContentBodyItemNumberWrapper>
                {activeButton === 'Purchase' ? (
                  <LeftContentBodyItemContentWrapper>
                    <LeftContentBodyItemTitle>
                      Confirm Purchase
                    </LeftContentBodyItemTitle>
                    <LeftContentBodyItemContent>
                      Review the desired amount details with transactions fees
                      and confirm the purchase.
                    </LeftContentBodyItemContent>
                  </LeftContentBodyItemContentWrapper>
                ) : (
                  <LeftContentBodyItemContentWrapper>
                    <LeftContentBodyItemTitle>
                      Confirm Withdrawal
                    </LeftContentBodyItemTitle>
                    <LeftContentBodyItemContent>
                      Review the desired amount details with transactions fees
                      and confirm the withdrawal.
                    </LeftContentBodyItemContent>
                  </LeftContentBodyItemContentWrapper>
                )}
              </LeftContentBodyItem>
            </LeftContentBody>
          </CashierLeftContent>

          <CashierRightContent>
            <CashierRightContentFirstBlock>
              <FirstBlockButtonWrapper>
                <FirstBlockButton
                  active={activeButton === 'Purchase'}
                  onClick={() => !disabled && handleButtonClick('Purchase')}
                >
                  Purchase
                </FirstBlockButton>
              </FirstBlockButtonWrapper>
              <FirstBlockButtonWrapper>
                <FirstBlockButton
                  active={activeButton === 'Withdraw'}
                  onClick={() => !disabled && handleButtonClick('Withdraw')}
                >
                  Withdraw
                </FirstBlockButton>
              </FirstBlockButtonWrapper>
            </CashierRightContentFirstBlock>

            {activeButton === 'Purchase' ? (
              <Purchase setDisabled={setDisabled} />
            ) : (
              <WithDraw setDisabled={setDisabled} />
            )}
          </CashierRightContent>
        </CashierMainWrapper>
      </CashierWrapper>
    </HomepageTemplate>
  );
};
