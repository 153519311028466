import { useEffect, useState } from 'react';
import { getGameDetail } from 'services/api';

export interface IGameInfo {
  gameId: string;
  name: string;
  description: string;
  scoringModel: string;
  backgroundImgUrl: string;
  backgroundColor: string;
  activeVersion: string;
  logoColor: string;
  tags: string[];
  orders: number;
  supportedVersions: { version: string }[];
  slug: string;
  isActive: boolean;
  isExternal: boolean;
  isStoshi: boolean;
  platformFeeRate: number;
  affiliateFeeRate: number;
  levelOwnerFeeRate: number;
  providerFeeRate?: number;
  treasuryFeeRate?: number;
  minLevel?: number;
  maxLevel?: number;
  adminInfo?: {
    username: string;
    avatarURL?: string;
  };
  providerInfo?: {
    username: string;
    avatarURL?: string;
  };
  treasuryInfo?: {
    username: string;
    avatarURL?: string;
  };
  defaultEntryFee: string;
  tokenAddress: string;
  tokenInfo: {
    address: string;
    decimals: number;
    name: string;
    symbol: string;
  };
}

export const useGetGameInfo = (gameId: string, ...refresh: any[]) => {
  const [gameInfo, setGameInfo] = useState<IGameInfo>();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    void (async () => {
      if (gameId) {
        setLoading(true);
        const res = await getGameDetail(gameId);
        if (res?.success) {
          setGameInfo(res?.payload?.data);
        }
        setLoading(false);
      }
    })();
  }, [gameId, ...refresh]);
  return { gameInfo, loading };
};
