import { List, Select, Spin, Tooltip } from 'antd';
import LeaderboardTable from 'components/Leaderboard';
import { GameScoringModelType } from 'constants/enum/GameScoringModelType';
import {
  getScoringModels,
  ScoringModelLeaderboardTypes,
} from 'constants/enum/ScoringModel';
import { IGameActive } from 'hooks/games/useGetGameActive';
import { useGetGameActiveForScroll } from 'hooks/games/useGetGameActiveForScroll';
import { useGetGameLevelDetail } from 'hooks/leaderboard/useGetGameLevelDetail';
import { useGetGameStatistic } from 'hooks/leaderboard/useGetGameStatic';
import { useGetListLevel } from 'hooks/leaderboard/useGetListLevel';
import { useCallback, useEffect, useState } from 'react';
import { removeBackground, setBackground } from 'redux/slices/template';
import { useAppDispatch } from 'redux/store';
import HomepageTemplate from 'template/Homepage';
import { formatNumberThousand } from 'utils/formatNumber';

import {
  ButtonText,
  ButtonWrapper,
  DetailLabelWrapper,
  DetailValueWrapper,
  DetailWrapper,
  GameWrapper,
  GeneralButtonWrapper,
  InformationWrapper,
  LeaderBoardWrapper,
  LeftWrapper,
  ListGameWrapper,
  RightWrapper,
  SelectMultiLevel,
} from './styled';

const { Option } = Select;

export const LeaderBoard = () => {
  const [tab, setTab] = useState(GameScoringModelType.level);
  const [gameParams, setGameParams] = useState({
    pageSize: 20,
    pageIndex: 1,
    scoringModelType: GameScoringModelType.level,
  });

  const {
    gameActive,
    loading: listGameLoading,
    total: listGameTotal,
  } = useGetGameActiveForScroll(gameParams);

  const [selectedLevel, setSelectedLevel] = useState<number>();

  const [selectedGameId, setSelectedGameId] = useState('');
  const [gameInfo, setGameInfo] = useState<IGameActive>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(removeBackground());
    };
  }, []);

  useEffect(() => {
    if (gameActive && gameActive.length > 0 && !selectedGameId) {
      const firstGame = gameActive[0];
      setSelectedGameId(firstGame.gameId);
      setGameInfo(firstGame);
      dispatch(setBackground(firstGame.backgroundImgUrl));
    }
  }, [gameActive, selectedGameId, dispatch]);

  useEffect(() => {
    if (gameActive && gameActive.length > 0) {
      const scoreModule = getScoringModels(gameActive[0].scoringModel);
      if (
        (tab == GameScoringModelType.score &&
          scoreModule == ScoringModelLeaderboardTypes.SCORE_BASED) ||
        (tab == GameScoringModelType.level &&
          scoreModule == ScoringModelLeaderboardTypes.LEVEL_BASED)
      ) {
        const firstGame = gameActive[0];
        setSelectedGameId(firstGame.gameId);
        setGameInfo(firstGame);
        dispatch(setBackground(firstGame.backgroundImgUrl));
      }
    }
  }, [tab, gameActive, dispatch]);

  const { gameLevelDetail } = useGetGameLevelDetail(selectedGameId);

  const { listLevel, loading } = useGetListLevel(selectedGameId, tab);

  const [listLv, setListLv] = useState<number[]>();

  useEffect(() => {
    if (listLevel) {
      const listLevelNumber = listLevel.map(lv => +lv);
      setListLv(listLevelNumber);
      setSelectedLevel(1);
    }
  }, [listLevel]);

  const handleLevelSelect = selected => {
    setSelectedLevel(selected);
  };

  const { gameStatistic } = useGetGameStatistic(
    selectedGameId,
    selectedLevel?.toString(),
    tab == GameScoringModelType.score,
  );

  const handleGameListScroll = useCallback(
    e => {
      if (
        e.currentTarget.scrollTop + e.currentTarget.offsetHeight >=
          e.currentTarget.scrollHeight &&
        !listGameLoading &&
        gameActive?.length < listGameTotal
      ) {
        setGameParams({
          ...gameParams,
          pageIndex: gameParams.pageIndex + 1,
        });
      }
    },
    [gameParams, listGameLoading, listGameTotal, gameActive],
  );

  return (
    <HomepageTemplate>
      <LeaderBoardWrapper>
        <LeftWrapper>
          <GeneralButtonWrapper>
            <ButtonWrapper
              active={tab === GameScoringModelType.level}
              onClick={() => {
                setGameParams({
                  ...gameParams,
                  pageIndex: 1,
                  scoringModelType: GameScoringModelType.level,
                });
                setTab(GameScoringModelType.level);
              }}
            >
              <ButtonText>Level</ButtonText>
            </ButtonWrapper>
            <ButtonWrapper
              active={tab === GameScoringModelType.score}
              onClick={() => {
                setGameParams({
                  ...gameParams,
                  pageIndex: 1,
                  scoringModelType: GameScoringModelType.score,
                });
                setTab(GameScoringModelType.score);
              }}
            >
              <ButtonText>Score</ButtonText>
            </ButtonWrapper>
          </GeneralButtonWrapper>

          <ListGameWrapper onScroll={handleGameListScroll}>
            <Spin spinning={listGameLoading}>
              <List
                dataSource={gameActive}
                renderItem={item => (
                  <List.Item
                    onClick={() => {
                      setSelectedGameId(item.gameId);
                      setGameInfo(item);
                      dispatch(setBackground(item.backgroundImgUrl));
                    }}
                  >
                    <Tooltip title={item.name} placement="top">
                      <GameWrapper
                        src={item.backgroundImgUrl}
                        selected={item.gameId === selectedGameId}
                        alt="Image"
                      />
                    </Tooltip>
                  </List.Item>
                )}
              />
            </Spin>
          </ListGameWrapper>
        </LeftWrapper>

        <RightWrapper>
          {tab === GameScoringModelType.score && (
            <SelectMultiLevel
              value={selectedLevel}
              onChange={handleLevelSelect}
            >
              {listLv?.map(level => {
                const levelDetail = gameLevelDetail.find(
                  detail => detail?.level === level.toString(),
                );
                return (
                  <Option key={level} value={level}>
                    {levelDetail ? levelDetail.levelName : `Level ${level}`}
                  </Option>
                );
              })}
            </SelectMultiLevel>
          )}

          <InformationWrapper>
            <DetailWrapper>
              <DetailLabelWrapper>Total unique players</DetailLabelWrapper>
              <DetailValueWrapper>
                {gameStatistic?.playerAmount?.toLocaleString() || 0}
              </DetailValueWrapper>
            </DetailWrapper>
            <DetailWrapper>
              <DetailLabelWrapper>Amount of Plays</DetailLabelWrapper>
              <DetailValueWrapper>
                {formatNumberThousand(
                  gameStatistic?.playsAmount || 0,
                ).toLocaleString()}
              </DetailValueWrapper>
            </DetailWrapper>
            <DetailWrapper>
              <DetailLabelWrapper>Amount of Revenue</DetailLabelWrapper>
              <DetailValueWrapper>
                $
                {parseFloat(gameStatistic?.totalOfUSDRevenue).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  },
                )}
              </DetailValueWrapper>
            </DetailWrapper>
          </InformationWrapper>

          <LeaderboardTable
            tab={tab}
            selectedGameId={selectedGameId}
            gameInfo={gameInfo}
            selectedLevel={selectedLevel}
            minLevel={gameStatistic?.minLevel}
            isScroll={true}
          />
        </RightWrapper>
      </LeaderBoardWrapper>
    </HomepageTemplate>
  );
};
