import { Tooltip } from 'antd';
import { IGameActive } from 'hooks/games/useGetGameActive';

import {
  GameDetailBodyWrapper,
  GameDetailFooterWrapper,
  GameDetailHeaderWrapper,
  GameDetailWrapper,
  GameLevelTitleWrapper,
  GameLevelWrapper,
  GameNameWrapper,
  Line,
  Parallelogram,
  ParallelogramDetail,
  ParallelogramWrapper,
} from './styled';

export const GameItem = (props: IGameActive & { showDetail: () => void }) => {
  return (
    <>
      <GameDetailWrapper>
        <GameDetailHeaderWrapper
          src={props?.backgroundImgUrl}
          onClick={() => props?.showDetail && props?.showDetail()}
        />

        <GameDetailBodyWrapper>
          <Tooltip title={props?.name}>
            <GameNameWrapper
              onClick={() => props?.showDetail && props?.showDetail()}
            >
              {props.name}
            </GameNameWrapper>
          </Tooltip>
          <GameLevelWrapper>
            <Tooltip title={props?.tags?.join(' / ')}>
              <GameLevelTitleWrapper>
                {props?.tags?.join(' / ')}
              </GameLevelTitleWrapper>
            </Tooltip>
          </GameLevelWrapper>
        </GameDetailBodyWrapper>

        <GameDetailFooterWrapper>
          <ParallelogramWrapper>
            <Parallelogram>
              <ParallelogramDetail>Total unique players</ParallelogramDetail>
            </Parallelogram>
            <Line />
            <Parallelogram small>
              <ParallelogramDetail>
                {props?.totalPlayers.toLocaleString() || 0}
              </ParallelogramDetail>
            </Parallelogram>
          </ParallelogramWrapper>

          <ParallelogramWrapper>
            <Parallelogram>
              <ParallelogramDetail>Total attempts</ParallelogramDetail>
            </Parallelogram>
            <Line />
            <Parallelogram small>
              <ParallelogramDetail>
                {props?.totalAttempts.toLocaleString() || 0}
              </ParallelogramDetail>
            </Parallelogram>
          </ParallelogramWrapper>

          <ParallelogramWrapper>
            <Parallelogram>
              {/* <Tooltip title="Total Revenue = Total amount of BIGA credits used in the game * 50% * the price of BIGA $0.1"> */}
              <ParallelogramDetail>Total revenue</ParallelogramDetail>
              {/* </Tooltip> */}
            </Parallelogram>
            <Line />
            <Parallelogram small>
              <ParallelogramDetail>
                $
                {Number(props?.totalRevenue || 0).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
              </ParallelogramDetail>
            </Parallelogram>
          </ParallelogramWrapper>
        </GameDetailFooterWrapper>
      </GameDetailWrapper>
    </>
  );
};
