import { Row, Spin, Tabs, TabsProps, Tooltip } from 'antd';
import { Option } from 'antd/lib/mentions';
import { ColumnsType } from 'antd/lib/table';
import defaultAvatarImg from 'assets/images/default-user-profile.png';
import { GameScoringModelType } from 'constants/enum/GameScoringModelType';
import { useGetRevenueByGame } from 'hooks/game-revenue/useGetRevenueByGame';
import { useGetGameActiveForScroll } from 'hooks/games/useGetGameActiveForScroll';
import { useEffect, useMemo, useRef, useState } from 'react';

import {
  AdminPanelTable,
  BodyContentWrapper,
  GameRevenueCell,
  HeaderContentItemWrapper,
  HeaderContentWrapper,
  SelectGameOption,
  TableContentWrapper,
  UserCellWrapper,
} from './styled';

export const GameRevenue = () => {
  const RevenueTable = ({
    gameScoringModelType,
  }: {
    gameScoringModelType: GameScoringModelType;
  }) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [selectedGame, setSelectedGame] = useState<string>('');

    const [gameParams, setGameParams] = useState({
      pageSize: 10,
      pageIndex: 1,
      scoringModelType: gameScoringModelType,
    });
    const [revenueParams, setRevenueParams] = useState({
      pageSize: 10,
      pageIndex: 1,
      scoringModel: gameScoringModelType,
      gameId: selectedGame ?? '',
    });
    const [refresh, setRefresh] = useState(false);

    const {
      gameActive: listGame,
      loading: listGameLoading,
      total: listGameTotal,
    } = useGetGameActiveForScroll(gameParams);

    const { revenue, loading: revenueLoading } = useGetRevenueByGame(
      revenueParams,
      refresh,
    );

    const handleListGameScroll = e => {
      const element: Element = e?.target;
      if (element.scrollHeight === element.clientHeight + element.scrollTop) {
        setGameParams(prevParams => ({
          ...prevParams,
          pageIndex: prevParams.pageIndex + 1,
        }));
      }
    };

    useEffect(() => {
      if (listGameLoading && listGame) {
        if (selectedGame === '') {
          setSelectedGame(listGame[0]?.gameId ?? '');
          setRevenueParams(prev => ({
            ...prev,
            gameId: listGame[0]?.gameId,
          }));
        }
      }
    }, [listGameLoading, listGame, selectedGame]);

    useEffect(() => {
      const tableType =
        gameScoringModelType === GameScoringModelType.level
          ? 'level-table'
          : 'score-table';

      const table = document.querySelector(
        `#${tableType} > .ant-table-container > .ant-table-body`,
      );
      // const table = document.querySelector('.ant-table-body');
      if (!table) return;
      table.addEventListener('scroll', handleTableScroll);

      return () => {
        table.removeEventListener('scroll', handleTableScroll);
      };
    }, [gameScoringModelType]);

    const handleTableScroll = (event: Event) => {
      const element: HTMLElement = event.target as HTMLElement;
      if (
        element.scrollHeight <=
        element.clientHeight + element.scrollTop + 10
      ) {
        setRevenueParams(prevParams => ({
          ...prevParams,
          pageSize: prevParams.pageSize + 10,
        }));
      }
    };

    const handleGameSelect = selected => {
      if (selected) {
        setSelectedGame(selected ?? '');
        setRevenueParams({
          pageIndex: 1,
          gameId: selected,
          pageSize: 10,
          scoringModel: gameScoringModelType,
        });
      }
    };

    const columns = useMemo(() => {
      if (gameScoringModelType) {
        const columnsByGameType = () => {
          switch (gameScoringModelType) {
            case GameScoringModelType.score:
              return [
                {
                  title: '',
                  dataIndex: 'key',
                  key: 'key',
                  render: value =>
                    gameParams.pageSize * (currentPage - 1) + value + 1,
                },
                {
                  title: 'Username',
                  dataIndex: 'username',
                  key: 'username',
                  render: (username, record) => {
                    return (
                      <UserCellWrapper>
                        <img src={record?.avatarURL || defaultAvatarImg} />
                        <div>{username}</div>
                      </UserCellWrapper>
                    );
                  },
                },
                {
                  title: <GameRevenueCell>Plays</GameRevenueCell>,
                  dataIndex: 'playsAmount',
                  key: 'playsAmount',
                  render: playsAmount => (
                    <GameRevenueCell>{playsAmount}</GameRevenueCell>
                  ),
                },
                {
                  title: 'Amount Credits Spent',
                  dataIndex: 'totalOfSpentAmount',
                  key: 'totalOfSpentAmount',
                  render: totalOfSpentAmount => (
                    <div>${Math.round(totalOfSpentAmount * 100) / 100}</div>
                  ),
                },
                {
                  title: 'Amount Revenue Made',
                  dataIndex: 'totalOfRevenue',
                  key: 'totalOfRevenue',
                  render: totalOfRevenue => (
                    <div>${Math.round(totalOfRevenue * 100) / 100}</div>
                  ),
                },
              ];
            case GameScoringModelType.level:
              return [
                {
                  title: '',
                  dataIndex: 'key',
                  key: 'key',
                  render: value =>
                    gameParams.pageSize * (currentPage - 1) + value + 1,
                },
                {
                  title: 'Username',
                  dataIndex: 'username',
                  key: 'username',
                  render: (username, record) => {
                    return (
                      <UserCellWrapper>
                        <img src={record?.avatarURL || defaultAvatarImg} />
                        <div>{username}</div>
                      </UserCellWrapper>
                    );
                  },
                },
                {
                  title: <GameRevenueCell>Plays</GameRevenueCell>,
                  dataIndex: 'playsAmount',
                  key: 'playsAmount',
                  render: playsAmount => (
                    <GameRevenueCell>{playsAmount}</GameRevenueCell>
                  ),
                },
                {
                  title: (
                    <Tooltip
                      title={'Level x Frequency of ownership'}
                      placement="top"
                    >
                      Levels Owned
                    </Tooltip>
                  ),
                  dataIndex: 'level',
                  key: 'level',
                  render: level => (
                    <div>
                      {level?.map((lv, ind) => (
                        <span key={ind}>
                          {lv}
                          <span>
                            {level.length > 1 &&
                              ind !== level.length - 1 &&
                              ', '}
                          </span>
                        </span>
                      ))}
                    </div>
                  ),
                },
                {
                  title: 'Amount Credits Spent',
                  dataIndex: 'totalOfSpentAmount',
                  key: 'totalOfSpentAmount',
                  render: totalOfSpentAmount => (
                    <div>${Math.round(totalOfSpentAmount * 100) / 100}</div>
                  ),
                },
                {
                  title: 'Amount Revenue Made',
                  dataIndex: 'totalOfRevenue',
                  key: 'totalOfRevenue',
                  render: totalOfRevenue => (
                    <div>${Math.round(totalOfRevenue * 100) / 100}</div>
                  ),
                },
              ];
            default:
              return [];
          }
        };
        return columnsByGameType();
      }
    }, [gameScoringModelType]);

    return (
      <>
        {' '}
        <HeaderContentWrapper>
          <HeaderContentItemWrapper>
            <SelectGameOption
              value={selectedGame}
              onChange={handleGameSelect}
              overwriteMarginTop="0px"
              overwriteMarginLeft="35px"
              isGamerDataTable={true}
              onPopupScroll={handleListGameScroll}
              loading={listGameLoading}
              defaultActiveFirstOption={true}
            >
              {listGame?.map((game, index) => {
                const isSpinner: boolean =
                  index === listGame.length - 1 ||
                  listGame[index].gameId === selectedGame;
                return (
                  <Option key={game.gameId} value={game.gameId}>
                    {listGameLoading
                      ? isSpinner && <Spin spinning={true} />
                      : ` ${game.name}`}
                  </Option>
                );
              })}
            </SelectGameOption>
          </HeaderContentItemWrapper>
        </HeaderContentWrapper>
        <BodyContentWrapper>
          <AdminPanelTable
            dataSource={revenue?.map((item, key) => ({ ...item, key }))}
            columns={columns}
            pagination={false}
            scroll={{ y: 400 }}
            loading={revenueLoading}
            id={
              gameScoringModelType === GameScoringModelType.level
                ? 'level-table'
                : 'score-table'
            }
          />
        </BodyContentWrapper>
      </>
    );
  };
  const items: TabsProps['items'] = [
    {
      key: 'leaderBoard',
      label: <span style={{ marginLeft: '1rem' }}>LeaderBoard Games</span>,
      children: (
        <RevenueTable gameScoringModelType={GameScoringModelType.score} />
      ),
    },
    {
      key: 'levelBased',
      label: 'Level-based Games',
      children: (
        <RevenueTable gameScoringModelType={GameScoringModelType.level} />
      ),
    },
  ];
  return (
    <TableContentWrapper style={{ paddingBottom: '2rem' }}>
      <Tabs
        defaultActiveKey="leaderBoard"
        items={items}
        style={{ backgroundColor: '#fff' }}
      />
    </TableContentWrapper>
  );
};
