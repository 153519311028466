import { Menu } from 'antd';
import { ReactComponent as LogoRedDot } from 'assets/vectors/logo-header-reddot.svg';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Text = styled.div`
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const HeaderWrapper = styled(Wrapper)<{ hasBackground?: boolean }>`
  gap: 22px;
  height: 64px;
  padding: 0px 24px;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(255, 255, 255, 0.16);

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  /* background: #0b3885; */

  background: ${props => (props.hasBackground ? 'transparent' : '#0b3885')};
`;

export const BigaCreditsWrapper = styled(Wrapper)`
  gap: 8px;
`;

export const BigaCreditsText = styled(Text)`
  color: rgba(255, 255, 255, 0.6);
  font-size: 16px;
  font-weight: 500;
`;

export const BigaCreditsValueText = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 700;
`;

export const Line = styled.div`
  width: 1px;
  height: 22px;
  background: rgba(255, 255, 255, 0.4);
`;

export const ConnectWalletWrapper = styled(Wrapper)`
  gap: 8px;
  cursor: pointer;
`;

export const LogoHeaderRedDot = styled(LogoRedDot)<{ active?: boolean }>`
  width: 8px;
  height: 8px;
  .header-connect-dot {
    fill: ${props => props?.active && '#68CE67'};
  }
`;

export const ConnectWalletText = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 600;
`;

export const MenuWrapper = styled(Menu)`
  display: inline-flex;
  padding-top: 40px;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  background: #fff;
  box-shadow: 0px 8px 60px 4px rgba(0, 0, 0, 0.16);
`;

export const MenuMainWrapper = styled(Wrapper)`
  gap: 40px;
  flex-direction: column;
`;

export const MenuMainTextWrapper = styled(Wrapper)`
  flex-direction: column;
  gap: 16px;
`;

export const ConnectTitleText = styled(Text)`
  color: #000;
  text-align: center;
  font-family: Changeling Neo;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
`;

export const ConnectDecriptionText = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
`;

export const MenuMainButtonWrapper = styled(Wrapper)`
  flex-direction: column;
  justify-content: center;
  gap: 16px;
`;

export const MenuMainButton = styled(Wrapper)<{ disabled?: boolean }>`
  width: 368px;
  height: 56px;
  padding: 10px 16px;
  justify-content: center;
  gap: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #fff;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
`;

export const MenuMainButtonLogo = styled.img`
  width: 24px;
  height: 24px;
  flex-shrink: 0;
`;

export const MenuMainButtonText = styled(Text)`
  color: #1d1d1d;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
`;

export const MenuMainDecriptionWrapper = styled(Wrapper)`
  width: 448px;
  padding: 24px 10px;
  justify-content: center;
  gap: 10px;
  background: var(--light-gray-6, #f2f2f7);
`;

export const MenuMainDecriptionText = styled(Text)`
  width: 361px;
  flex-shrink: 0;
  color: #1d1d1d;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
`;

export const MenuMainDecriptionStepsText = styled.span`
  color: #0b3885;
  font-weight: 600;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const ConnectButtonWrapper = styled(Wrapper)`
  gap: 16px;
`;

export const ConnectButtonText = styled(Text)`
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;
