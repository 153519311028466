import { Drawer, Input, Select, Tooltip } from 'antd';
import { ReactComponent as SearchIcon } from 'assets/vectors/logo-games-search.svg';
import styled from 'styled-components';

export const GameDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const GameDetailHeaderWrapper = styled.img`
  width: 100%;
  /* height: 400px; */
  object-fit: contain;
  object-position: left bottom;

  cursor: pointer;
`;

export const GameDetailBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const GameNameWrapper = styled.div`
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;

  cursor: pointer;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const GameLevelWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const GameLevelTitleWrapper = styled.div`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  color: #fff;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const GameDetailFooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ParallelogramWrapper = styled.div`
  display: flex;
  gap: 6px;
`;

export const Parallelogram = styled.div<{
  small?: boolean;
}>`
  min-width: ${props => (props.small ? '99px' : '139px')};
  height: 32px;
  transform: skew(-20deg);
  background-color: rgba(255, 255, 255, 0.08);

  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;
`;

export const ParallelogramDetail = styled.div`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  font-family: Neue Haas Grotesk Display Pro;

  transform: skew(20deg);
`;

export const Line = styled.div`
  width: 2px;
  height: 32px;
  transform: skew(-20deg);
  background-color: rgba(255, 255, 255, 0.16);
`;

export const DrawerWrapper = styled(Drawer)`
  & > .ant-drawer-content-wrapper {
    top: 24px;
    right: 24px;
    bottom: 24px;
    width: 566px !important;
    max-width: 100%;
    .ant-drawer-body {
      padding: 0;
    }
    .ant-drawer-body::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const DrawerGeneralWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0 40px 0;
  min-height: 100%;
  justify-content: space-between;
`;

export const DrawerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const DrawerContentHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 24px;
`;

export const DrawerTitleGameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10.177px;
`;

export const DrawerNameGameWrapper = styled.div`
  color: var(--biga-blue, #0a3885);
  font-family: Changeling Neo;
  font-size: 32px;
  font-style: normal;
  font-weight: 701;
  line-height: normal;
  text-transform: uppercase;
`;

export const DrawerLabelGameGeneralWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  gap: 10.177px;
`;

export const DrawerLabelGameWrapper = styled.div`
  padding: 5.088px 7.633px;

  &:nth-child(odd) {
    background: var(--biga-blue, #0a3885);
  }

  &:nth-child(even) {
    background: var(--gold-grey, #878273);
  }
`;

export const DrawerLabelGame = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const DrawerDecriptionGameWrapper = styled.div`
  color: #000;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 16px;
  /* text-align: justify; */
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  opacity: 0.8;

  height: 100px;
  overflow: auto;
`;

export const DrawerContentBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DrawerTotalDetailWrapper = styled.div`
  display: flex;
  padding: 40px 24px;
  justify-content: space-evenly;
  align-items: center;
  gap: 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const DrawerTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
`;

export const DrawerTotalTitleWrapper = styled.div`
  color: #000;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.4;
`;

export const DrawerTotalValueWrapper = styled.div`
  color: var(--biga-blue, #0a3885);
  text-align: center;
  font-family: Roboto;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const DrawerSearchBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const SearchOutlinedStyled = styled(SearchIcon)`
  cursor: pointer;
  user-select: none;
`;

export const DrawerSearchWrapper = styled(Input)`
  color: #000;
  background-color: rgba(10, 56, 133, 0.04);
  /* width: 75%; */
  color: #000;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 16px;
  text-align: justify;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  opacity: 0.8;
  padding: 13.5px 18px;

  .ant-input-affix-wrapper,
  .ant-input {
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;

    color: #000;
    font-family: Neue Haas Grotesk Display Pro;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:focus,
    &:hover,
    &.ant-input-affix-wrapper:focus,
    &.ant-input-affix-wrapper:hover {
      border: none;
      outline: none;
      box-shadow: none;
    }
    &::placeholder {
      color: #000;
      opacity: 0.24;
    }
    &:-ms-input-placeholder {
      color: #000;
      opacity: 0.24;
    }
    &::-ms-input-placeholder {
      color: #000;
      opacity: 0.24;
    }
    .anticon.anticon-close-circle {
      color: #000;
      opacity: 0.24;
    }
  }

  .ant-input-group-addon {
    background-color: transparent;
    border: none;
  }
`;

export const DrawerTotalIncomeWrapper = styled.div`
  display: flex;
  padding: 40px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const DrawerTotalIncomeValue = styled.div`
  color: #000;
  text-align: center;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const DrawerTotalIncomeTitle = styled.div`
  color: #000;
  text-align: center;
  font-family: Neue Haas Grotesk Display Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.4;
`;

export const DrawerButtonPlayWrapper = styled.div`
  border: 1px solid rgba(135, 130, 115, 0.16);
  background: rgba(135, 130, 115, 0.16);
  padding: 8px;
`;

export const DrawerButtonPlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background: #0b3885;
`;

export const DrawerButton = styled.div`
  padding: 12px 40px;
  cursor: pointer;
  user-select: none;

  color: #fff;
  text-align: center;
  font-family: Changeling Neo;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
`;

export const SelectStyled = styled(Select)<{
  bgColor?: string;
}>`
  padding: 12px 0;
  width: 25%;
  text-align: center;
  cursor: pointer;
  background-color: rgba(10, 56, 133, 0.04);

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    display: flex;
    align-items: center;
    border: none;
    height: 100%;
    background-color: transparent;
  }
  .ant-select-arrow {
    color: #000;
    opacity: 0.8;
  }
  &.ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border-color: #000;
    box-shadow: none;
  }
  .ant-select-selection-item {
    color: #000;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    line-height: normal;
    font-family: Neue Haas Grotesk Display Pro;
  }
`;

export const TotalRevenueTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    letter-spacing: 1px;
  }
`;

export const GamePlayAndFeeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 20px;
`;
