import Header from 'components/Layout/Header';
import { Navbar } from 'components/Layout/Navbar';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'redux/store';
import { ThemeProvider } from 'styled-components';
import { Desktop } from 'styles/Common/HeaderDesktop.styled';

import { MainLayout } from './styled';

interface LayoutProps {
  theme?: string;
}

export default function Layout({ theme }: LayoutProps) {
  const collapsed = useAppSelector(state => state.navbar.collapsed);
  return (
    <>
      <Desktop style={{ backgroundColor: theme }}>
        <ThemeProvider
          theme={{
            collapsed,
          }}
        >
          <Navbar theme={theme} />
          {/* <MainLayout>
            <Header theme={theme} />
            <Outlet />
          </MainLayout> */}
          <Outlet />
        </ThemeProvider>
      </Desktop>
    </>
  );
}
