// eslint-disable-next-line simple-import-sort/imports
import efLogo from 'assets/vectors/logo-EJ@2x.svg';
import gdsLogo from 'assets/vectors/logo-GDS.svg';
import carvLogo from 'assets/vectors/logo-carv.svg';
import geminiLogo from 'assets/vectors/logo-gemini.svg';
import hexTrustLogo from 'assets/vectors/logo-hex-trust.svg';
import jackpotLogo from 'assets/vectors/logo-jackpot.svg';
import loveMonsterLogo from 'assets/vectors/logo-love-monster.svg';
import madLogo from 'assets/vectors/logo-mad.svg';
import makCapitalLogo from 'assets/vectors/logo-mak-capital.svg';
import soulboundLogo from 'assets/vectors/logo-soulbound.svg';
import space3Logo from 'assets/vectors/logo-space3.svg';
import stoshiLogo from 'assets/vectors/logo-stoshi.svg';
import w3ggLogo from 'assets/vectors/logo-w3gg.svg';

import {
  LandingPartnerListWrapper,
  LandingPartnerSectionWrapper,
} from './styled';

export const LandingPartnersSection = () => {
  return (
    <LandingPartnerSectionWrapper>
      <div className="partner-title">Partners</div>
      <LandingPartnerListWrapper>
        <img src={geminiLogo} alt="Gemini" />
        <img src={hexTrustLogo} alt="Hex Trust" />
        <img src={madLogo} alt="MAD" />
        <img src={makCapitalLogo} alt="Mak Capital" />
        <img src={gdsLogo} alt="GDS" />
        <img src={stoshiLogo} alt="Stoshi" />
        <img src={efLogo} alt="EF" />
      </LandingPartnerListWrapper>
      <LandingPartnerListWrapper>
        <img src={w3ggLogo} alt="We Guide Games" />
        <img src={carvLogo} alt="Carv" />
        <img src={jackpotLogo} alt="Jackpot" />
        <img src={loveMonsterLogo} alt="Love Monster" />
        <img src={soulboundLogo} alt="Soulbound" />
        <img src={space3Logo} alt="Space3" />
      </LandingPartnerListWrapper>
    </LandingPartnerSectionWrapper>
  );
};
