import discord from 'assets/icons/discord.svg';
import instagram from 'assets/icons/instagram.svg';
import mail from 'assets/icons/mail.svg';
import twitter from 'assets/icons/twitter.svg';
import { Link } from 'react-router-dom';

import { SocialIcon, SocialText, SocialWrapper } from './styled';

export const ListSocial = () => {
  return (
    <SocialWrapper>
      <SocialText>Join us on</SocialText>
      <Link to="https://twitter.com/bigaarcade" target="_blank">
        <SocialIcon src={twitter} alt="twitter" />
      </Link>
      <Link to="https://www.instagram.com/bigaarcade/" target="_blank">
        <SocialIcon src={instagram} alt="instagram" />
      </Link>
      <Link to="https://discord.gg/nGcGdS7tT3" target="_blank">
        <SocialIcon src={discord} alt="discord" />
      </Link>
      <Link to="mailto:hello@bigarcade.org" target="_blank">
        <SocialIcon src={mail} alt="mail" />
      </Link>
    </SocialWrapper>
  );
};
