import { Spin, UploadFile, UploadProps } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import closeicon from 'assets/vectors/logo-profile-close.svg';
import { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import { loginUser, refreshAvatar } from 'redux/slices/player';
import { useAppSelector } from 'redux/store';
import { setupProfile } from 'services/api';
import { dataURLToBlob } from 'utils/image';

import {
  ClearButton,
  ClearButtonWrapper,
  CloseIcon,
  CloseWrapper,
  CropText,
  CropWrapper,
  ModalAvatarButtonWrapper,
  ModalCropAvatarWrapper,
  ModalEditAvatarWrapper,
  ModalEditContainer,
  ModalEditWrapper,
  ModalWrapper,
  SaveImageButton,
  SaveImageButtonWrapper,
  UploadProfileDragger,
} from './styled';

export const UploadAvatar = ({ onRefresh }: { onRefresh: () => void }) => {
  const open = useModalIsOpen(ApplicationModal.PROFILE_UPLOAD_AVATAR);
  const toggleModal = useToggleModal(ApplicationModal.PROFILE_UPLOAD_AVATAR);
  const [avatar, setAvatar] = useState<UploadFile>();
  const [savedImage, setSavedImage] = useState('');
  const inputRef = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { playerInfo } = useAppSelector(state => state.player);

  const [form] = useForm();
  const dispatch = useDispatch();

  const getBase64 = (file: any): Promise<string | null | ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => resolve(e.target ? e.target.result : null);
      reader.onerror = error => reject(error);
    });
  };

  const uploadProps: UploadProps = {
    name: 'avatar',
    multiple: false,
    beforeUpload: async (_, fileList) => {
      handleAvatar();

      const files = [];
      for (const file of fileList) {
        const slip = file as UploadFile;
        if (slip && !slip.url && !slip.preview) {
          const preview = (await getBase64(slip))?.toString() || '';
          slip.preview = preview;
          slip.url = preview;
          slip.thumbUrl = preview;
        }
        files.push(slip);
      }
      setAvatar(files[0]);
      form.setFieldValue('avatar', files);
      await form.validateFields();
      return false;
    },
    fileList: avatar ? [avatar] : [],
    accept: 'image/jpg,image/jpeg,image/png,image/JPG,image/JPEG,image/PNG',
    onPreview: async (file: any) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file);
      }
    },
    showUploadList: false,
    maxCount: 1,
  };

  const handleClear = () => {
    setAvatar(undefined);
    form.setFieldValue('avatar', []);
    form.validateFields();
  };

  const handleSave = async () => {
    const canvasScaled = inputRef.current.getImageScaledToCanvas();
    const dataURL = canvasScaled.toDataURL();
    setSavedImage(dataURL);

    try {
      const blob = dataURLToBlob(dataURL);
      const { type, name, uid } = avatar as UploadFile;
      const croppedAvatar = new File([blob as BlobPart], name, { type });
      Object.assign(croppedAvatar, { uid });

      setLoading(true);

      const formData = new FormData();
      formData.append('avatar', croppedAvatar as any);
      formData.append('username', playerInfo.username);

      const res = await setupProfile(formData);
      if (res?.success) {
        setAvatar(undefined);
        setSavedImage('');
        const updatedPlayerInfo = {
          ...playerInfo,
          ...res?.payload?.data,
        };
        dispatch(loginUser(updatedPlayerInfo));
        dispatch(refreshAvatar());
        toast.success('Avatar updated successfully');
        onRefresh();

        toggleModal();
      } else {
        toast.error(res?.message || 'Setup Profile Failed');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setAvatar(undefined);
    setSavedImage('');
    toggleModal();
  };

  const handleAvatar = () => {
    setAvatar(undefined);
    setSavedImage(undefined);
    form.setFieldValue('avatar', []);
    form.validateFields();
  };
  return (
    <ModalWrapper
      visible={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={null}
      closable={false}
      centered
      width="448px"
    >
      <ModalEditAvatarWrapper>
        <CloseWrapper>
          <CloseIcon src={closeicon} onClick={handleCancel} />
        </CloseWrapper>
        <ModalEditContainer>
          <Spin spinning={loading}>
            <ModalEditWrapper>
              <ModalCropAvatarWrapper>
                {!avatar ? (
                  <UploadProfileDragger {...uploadProps}>
                    <CropWrapper />
                  </UploadProfileDragger>
                ) : (
                  <AvatarEditor
                    ref={inputRef}
                    image={savedImage || avatar?.url}
                    width={211}
                    height={211}
                    border={50}
                    color={[255, 255, 255, 0.6]}
                    scale={1}
                    rotate={0}
                  />
                )}
                <CropText>Drag and drop to adjust</CropText>
              </ModalCropAvatarWrapper>
              <ModalAvatarButtonWrapper>
                <ClearButtonWrapper>
                  <ClearButton onClick={handleClear}>Clear</ClearButton>
                </ClearButtonWrapper>

                <SaveImageButtonWrapper>
                  <SaveImageButton onClick={() => avatar && handleSave()}>
                    Save new image
                  </SaveImageButton>
                </SaveImageButtonWrapper>
              </ModalAvatarButtonWrapper>
            </ModalEditWrapper>
          </Spin>
        </ModalEditContainer>
      </ModalEditAvatarWrapper>
    </ModalWrapper>
  );
};
