import { formatUnits } from '@ethersproject/units';
import { Empty, Spin } from 'antd';
import closeicon from 'assets/vectors/logo-profile-close.svg';
import { useGetPlayerGameRevenueBreakdownForScroll } from 'hooks/profile/useGetPlayerGameRevenueBreakdownForScroll';
import { useCallback, useState } from 'react';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';

import {
  BodyModalContentWrapper,
  BodyModalInfoWrapper,
  BodyModalWrapper,
  CloseIcon,
  CloseWrapper,
  ContentItemLeftDetailWrapper,
  ContentItemLeftImgWrapper,
  ContentItemLeftTitle,
  ContentItemLeftValue,
  ContentItemRightTitle,
  ContentItemRightValue,
  ModalContentItemLeftWrapper,
  ModalContentItemRightWrapper,
  ModalContentItemWrapper,
  ModalInfoWrapper,
  ModalLevelMasterWrapper,
  ModalWrapper,
} from './styled';

export const LevelMasters = ({
  levelMastered,
  gameLevelMastered,
}: {
  levelMastered?: number;
  gameLevelMastered?: number;
}) => {
  const open = useModalIsOpen(ApplicationModal.PROFILE_LEVEL_MASTERS);
  const toggleModal = useToggleModal(ApplicationModal.PROFILE_LEVEL_MASTERS);
  const handleCancel = () => {
    toggleModal();
  };

  const [refresh, setRefresh] = useState<boolean>(false);
  const [params, setParams] = useState({
    pageSize: 20,
    pageIndex: 1,
    isCurrentMaster: true,
  });

  const {
    playerGameRevenueList,
    loading: levelMasterLoading,
    total: levelMasterTotal,
  } = useGetPlayerGameRevenueBreakdownForScroll(params, refresh);

  const handleScroll = useCallback(
    e => {
      if (
        e.currentTarget.scrollTop + e.currentTarget.offsetHeight >=
          e.currentTarget.scrollHeight &&
        !levelMasterLoading &&
        playerGameRevenueList?.length < levelMasterTotal
      ) {
        setParams({
          ...params,
          pageIndex: params.pageIndex + 1,
        });
      }
    },
    [params, levelMasterLoading, levelMasterTotal, playerGameRevenueList],
  );

  return (
    <ModalWrapper
      visible={open}
      onCancel={handleCancel}
      maskClosable={true}
      footer={null}
      closable={false}
      width="646px"
    >
      <ModalLevelMasterWrapper>
        <CloseWrapper>
          <CloseIcon src={closeicon} onClick={handleCancel} />
        </CloseWrapper>

        <BodyModalWrapper>
          <BodyModalInfoWrapper>
            <ModalInfoWrapper>Revenue Breakdown</ModalInfoWrapper>
          </BodyModalInfoWrapper>

          <Spin spinning={levelMasterLoading}>
            <BodyModalContentWrapper
              onScroll={handleScroll}
              isEmpty={!playerGameRevenueList?.length}
            >
              {playerGameRevenueList?.length ? (
                playerGameRevenueList?.map(
                  (
                    {
                      level,
                      gameName,
                      gameId,
                      revenueAmount,
                      gameUrl,
                      gameScoringModelType,
                      currentRank,
                      currentScore,
                    },
                    index,
                  ) => (
                    <ModalContentItemWrapper key={index}>
                      <ModalContentItemLeftWrapper>
                        <ContentItemLeftImgWrapper src={gameUrl || null} />
                        <ContentItemLeftDetailWrapper>
                          <ContentItemLeftTitle>
                            {gameName}
                          </ContentItemLeftTitle>
                          {gameScoringModelType === 'LevelBased' ? (
                            <ContentItemLeftValue>
                              Level: {level}
                            </ContentItemLeftValue>
                          ) : (
                            <>
                              <ContentItemLeftValue>
                                Current rank: {currentRank + 1}
                              </ContentItemLeftValue>
                              <ContentItemLeftValue>
                                Score: {currentScore}
                              </ContentItemLeftValue>
                            </>
                          )}
                        </ContentItemLeftDetailWrapper>
                      </ModalContentItemLeftWrapper>
                      <ModalContentItemRightWrapper>
                        <ContentItemRightValue>
                          {'$'}
                          {revenueAmount.amount
                            ? (
                                parseFloat(
                                  formatUnits(
                                    revenueAmount.amount,
                                    revenueAmount.decimals,
                                  ),
                                ) / 10
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : 0}
                        </ContentItemRightValue>
                        <ContentItemRightTitle>Revenue</ContentItemRightTitle>
                      </ModalContentItemRightWrapper>
                    </ModalContentItemWrapper>
                  ),
                )
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              )}
            </BodyModalContentWrapper>
          </Spin>
        </BodyModalWrapper>
      </ModalLevelMasterWrapper>
    </ModalWrapper>
  );
};
