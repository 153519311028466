import { Button } from 'antd';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const CashierRightContentThirdBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const ThirdBlockTitle = styled(Text)`
  color: #000;
  opacity: 0.6;
  font-size: 16px;
  font-weight: 500;
`;

export const ThirdBlockValue = styled(Text)`
  color: #000;
  font-size: 40px;
  font-weight: 700;
`;

export const ThirdBlockUnit = styled(Text)`
  font-size: 16px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
`;

export const SecondBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  gap: 24px;
`;

export const SecondBlockItemWrapper = styled.div`
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #f6f6f9;
`;

export const SecondBlockItem = styled.div`
  display: flex;
  padding: 12px 0px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

export const SecondBlockItemTitle = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
`;

export const SecondBlockItemValue = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
`;

export const SecondBlockTotalWrapper = styled.div`
  display: flex;
  padding: 12px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid rgba(0, 0, 0, 0.08);
  background: #f6f6f9;
`;

export const SecondBlockTotalTitle = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
`;

export const SecondBlockTotalValue = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 700;

  text-align: right;
`;

export const ThirdBlock = styled.div`
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const ThirdBlockButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  background: #fff;
  width: 100%;
`;

export const ThirdBlockButtonBackWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 16px 32px;
  user-select: none;
  cursor: pointer;
  flex: 1;
  width: 50%;
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

export const ThirdBlockButtonBack = styled(Text)`
  color: #000;
  font-size: 18px;
  font-weight: 500;
`;

export const ThirdBlockButtonConfirmWrapper = styled(Button)`
  padding: 16px 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  outline: 0;
  padding: 0;
  width: 50%;
  border: none;
  height: auto;
  background: #0a3885;

  color: #fff;
  font-weight: 600;
  font-size: 18px;

  &.ant-btn:active:not(:disabled),
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    background: var(--biga-blue);
    outline: 0;
  }
  &.ant-btn:focus:not(:disabled),
  &.ant-btn:hover:not(:disabled) {
    color: #fff;
    border: none;
    background: var(--biga-blue);
    outline: 0;
  }
`;

export const ThirdBlockButtonGeminiWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid rgba(11, 56, 133, 0.24);
  user-select: none;
  cursor: pointer;
  padding: 16px 0;
`;

export const ThirdBlockButtonGemini = styled(Text)`
  color: #1d1d1d;
  font-size: 18px;
  font-weight: 500;
`;

export const SecondBlockContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;
