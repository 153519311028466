import { Tooltip } from 'antd';
import { PitRouter } from 'constants/routers';
import { formatUnits } from 'ethers/lib/utils';
import { useGetAffiliate } from 'hooks/affiliate/useGetAffiliate';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import HomepageTemplate from 'template/Homepage';

import {
  AffiliateContent,
  AffiliateContentItem,
  AffiliateContentItemTitle,
  AffiliateContentItemValue,
  AffiliateContentTitle,
  AffiliateContentWrapper,
  AffiliateWrapper,
  UnderlineText,
} from './styled';

export const Affiliate = () => {
  const { affiliateInfo, loading } = useGetAffiliate();

  return (
    <HomepageTemplate>
      <AffiliateWrapper>
        <AffiliateContentWrapper>
          <AffiliateContentTitle>Affiliate Statistics</AffiliateContentTitle>
          <AffiliateContent>
            <AffiliateContentItem>
              <AffiliateContentItemTitle>
                Total unique players
              </AffiliateContentItemTitle>
              <AffiliateContentItemValue>
                {affiliateInfo?.uniquePlayers?.toLocaleString() || 0}
              </AffiliateContentItemValue>
            </AffiliateContentItem>
            <AffiliateContentItem>
              <AffiliateContentItemTitle>
                Total attempts
              </AffiliateContentItemTitle>
              <AffiliateContentItemValue>
                {affiliateInfo?.totalAttempts?.toLocaleString() || 0}
              </AffiliateContentItemValue>
            </AffiliateContentItem>
            <AffiliateContentItem>
              <AffiliateContentItemTitle>
                Total revenue
              </AffiliateContentItemTitle>
              <AffiliateContentItemValue>
                {affiliateInfo?.totalEarnedAmount?.amount ? (
                  <>
                    {parseFloat(
                      formatUnits(
                        affiliateInfo.totalEarnedAmount.amount,
                        affiliateInfo.totalEarnedAmount.decimals || 18,
                      ),
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{' '}
                    {affiliateInfo.totalEarnedAmount.symbol}
                    {' ($'}
                    {(
                      parseFloat(
                        formatUnits(
                          affiliateInfo.totalEarnedAmount.amount,
                          affiliateInfo.totalEarnedAmount.decimals || 18,
                        ),
                      ) / 10
                    ).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    {')'}
                  </>
                ) : (
                  0
                )}
              </AffiliateContentItemValue>
            </AffiliateContentItem>
          </AffiliateContent>
        </AffiliateContentWrapper>

        <AffiliateContentWrapper>
          <AffiliateContentTitle>Affiliate Link</AffiliateContentTitle>
          <AffiliateContent>
            <AffiliateContentItem>
              <AffiliateContentItemTitle>
                {location.origin +
                  PitRouter.REGISTER +
                  '/' +
                  affiliateInfo?.affiliateCode}
              </AffiliateContentItemTitle>
              <CopyToClipboard
                onCopy={() => {
                  toast.success('COPY successfully');
                }}
                text={
                  location.origin +
                  PitRouter.REGISTER +
                  '/' +
                  affiliateInfo?.affiliateCode
                }
              >
                <Tooltip title="Copy">
                  <UnderlineText>Copy</UnderlineText>
                </Tooltip>
              </CopyToClipboard>
            </AffiliateContentItem>
          </AffiliateContent>
        </AffiliateContentWrapper>
      </AffiliateWrapper>
    </HomepageTemplate>
  );
};
