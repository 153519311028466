import { CloseCircleOutlined } from '@ant-design/icons';
import { Col, Form, Modal, Radio, Row, Spin, UploadFile } from 'antd';
import { GameType } from 'constants/enum/GameType';
import { PlayerRoles } from 'constants/enum/PlayerRole';
import { useCheckGamePathAvailable } from 'hooks/games/useCheckGamePathAvailable';
import { useGetScoringModelList } from 'hooks/games/useGetScoringModelList';
import { useGetPlayer } from 'hooks/player/useGetPlayer';
import usePlayerCallback from 'hooks/player/usePlayerCallback';
import {
  ISupportedToken,
  useGetListSupportedTokenForScroll,
} from 'hooks/supported-token/useGetListSupportedTokenForScroll';
import { UploadFileDTO } from 'interfaces/dto/UploadFileDTO';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import { toast } from 'react-toastify';
import { useDebounce } from 'react-use';
import { ApplicationModal } from 'redux/slices/application';
import { useModalIsOpen, useToggleModal } from 'redux/slices/application/hook';
import { useAppSelector } from 'redux/store';
import { ICheckUsernameExists } from 'services/api';
import { dataURLToBlob } from 'utils/image';

import { IntegrateInfo, ReviewIntegrate } from '../ReviewIntegrate';
import { StyledForm, SubmitButton, ThemedModal, Wrapper } from '../styled';
import {
  BackgroundGameImage,
  CheckBoxStyled,
  CheckBoxText,
  CheckBoxWrapper,
  DividerStyled,
  FormItemInput,
  FormItemWrapper,
  GroupTitleStyled,
  ImageCropperWrapper,
  InputField,
  InputWrapper,
  LabelStyled,
  RadioGroupStyled,
  SelectStyled,
  StyledTextArea,
  SuccessStyled,
  TextField,
  UploadDragger,
  UploadFileZipWrapper,
  UploadText,
  WarningStyled,
} from './styled';

import 'cropperjs/dist/cropper.css';

export const IntegrateGameModal = ({
  onRefresh,
}: {
  onRefresh: () => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const inputRef = useRef(null);

  const [gameName, setGameName] = useState('');
  const [description, setDescription] = useState('');
  const [slug, setSlug] = useState('');
  const [backgroundImg, setBackgroundImg] = useState<UploadFileDTO>();
  const [previewBackgroundImg, setPreviewBackgroundImg] = useState('');
  const [savedBackgroundImg, setSavedBackgroundImg] = useState('');
  const [buildFile, setBuildFile] = useState<UploadFileDTO>();
  const [gameVersion, setGameVersion] = useState('');
  const [token, setToken] = useState<ISupportedToken>(undefined);
  const [entryFee, setEntryFee] = useState('');
  const [provider, setProvider] = useState('');
  const [treasury, setTreasury] = useState('');

  const [isExternal, setIsExternal] = useState<boolean>(false);
  const [isStoshi, setIsStoshi] = useState<boolean>(false);
  const [providerProfit, setProviderProfit] = useState<number>(0);
  const [tags, setTags] = useState<string[]>([]);
  const [levelOwnerProfit, setLevelOwnerProfitt] = useState<number>(0);
  const [thirdPartyProfit, setThirdPartyProfit] = useState<number>(0);
  const [affiliateProfit, setAffiliateProfit] = useState<number>(0);
  const [scoringModel, setScoringModel] = useState<string>();
  const [minLevel, setMinLevel] = useState('');
  const [maxLevel, setMaxLevel] = useState('');

  const [scrollToken, setScrollToken] = useState<boolean>(false);
  const { listToken, loading: listTokenLoading } =
    useGetListSupportedTokenForScroll(scrollToken);

  const { scoringModeList, loading: scoringModelLoading } =
    useGetScoringModelList();

  const { checkUsernameExists } = usePlayerCallback();

  const [providerExists, setProviderExists] =
    useState<ICheckUsernameExists>(null);
  const [checkingProvider, setCheckingProvider] = useState<boolean>(false);
  const [treasuryExists, setTreasuryExists] =
    useState<ICheckUsernameExists>(null);
  const [checkingTreasury, setCheckingTreasury] = useState<boolean>(false);

  useDebounce(
    () => {
      void (async () => {
        if (provider) {
          setCheckingProvider(true);
          const result = await checkUsernameExists(provider);
          setProviderExists(result);
          setCheckingProvider(false);
        }
      })();
    },
    500,
    [provider],
  );
  useDebounce(
    () => {
      void (async () => {
        if (treasury) {
          setCheckingTreasury(true);
          const result = await checkUsernameExists(treasury);
          setTreasuryExists(result);
          setCheckingTreasury(false);
        }
      })();
    },
    500,
    [treasury],
  );

  const onRefreshSuccess = () => {
    setGameName('');
    setDescription('');
    setSlug('');
    handleBackgroundImg();
    setBuildFile(undefined);
    setGameVersion('');
    setToken(undefined);
    setEntryFee('');
    setProvider('');
    setTreasury('');
    setLevelOwnerProfitt(0);
    setMinLevel('');
    setMaxLevel('');
    setProviderProfit(0);
    setThirdPartyProfit(0);
    setAffiliateProfit(0);
    setIsExternal(false);
    setIsStoshi(false);
    setTags([]);
    setScoringModel(undefined);

    form.resetFields();
    onRefresh();
  };

  const isOpen = useModalIsOpen(ApplicationModal.REGISTER_GAME);
  const integrateGameToggle = useToggleModal(ApplicationModal.REGISTER_GAME);
  const [form] = Form.useForm();

  const reviewToggle = useToggleModal(ApplicationModal.REVIEW_INTEGRATE);

  const onFinish = async (values: any) => {
    if (values) {
      try {
        reviewToggle();
      } catch (e) {
        console.log(e.message);
        setLoading(false);
      }
    }
  };

  const handleProviderProfitChange = value => {
    value = value?.toString();
    setProviderProfit(
      value && value.indexOf('.') >= 0
        ? value.slice(0, value.indexOf('.') + 3)
        : value,
    );
  };

  const handleExternalChange = value => {
    setIsExternal(value);
    setIsStoshi(false);
  };

  const handleStoshiChange = value => {
    setIsStoshi(value);
  };

  const handleLevelOwnerProfitChange = value => {
    value = value?.toString();
    setLevelOwnerProfitt(
      value && value.indexOf('.') >= 0
        ? value.slice(0, value.indexOf('.') + 3)
        : value,
    );
  };

  const handleTreasuryProfitChange = value => {
    value = value?.toString();
    setThirdPartyProfit(
      value && value.indexOf('.') >= 0
        ? value.slice(0, value.indexOf('.') + 3)
        : value,
    );
  };

  const handleAffiliateProfitChange = value => {
    value = value?.toString();
    setAffiliateProfit(
      value && value.indexOf('.') >= 0
        ? value.slice(0, value.indexOf('.') + 3)
        : value,
    );
  };

  const handleEntryFeeChange = value => {
    if (token) {
      value = value?.toString();
      setEntryFee(
        value && value.indexOf('.') >= 0
          ? value.slice(0, value.indexOf('.') + Number(token.decimals) + 1)
          : value,
      );
    } else {
      setEntryFee(value);
    }
  };

  const handleTokenChange = value => {
    const selectedToken = listToken.find(({ address }) => value === address);
    setToken(selectedToken);
    if (selectedToken) {
      const fee = entryFee?.toString();
      setEntryFee(
        fee && fee.indexOf('.') >= 0
          ? fee.slice(0, fee.indexOf('.') + Number(selectedToken.decimals) + 1)
          : fee,
      );
    }
  };

  const handleKeyDown = e => {
    const keyCode = e.keyCode || e.which;
    const keyValue = String.fromCharCode(keyCode);
    const validKey = /^\d+$/.test(keyValue);
    if (!validKey) {
      e.preventDefault();
    }
  };

  const onCloseModal = () => {
    integrateGameToggle();
    form.resetFields();
    setGameName('');
    setDescription('');
    setSlug('');
    handleBackgroundImg();
    setBuildFile(undefined);
    setGameVersion('');
    setToken(undefined);
    setEntryFee('');
    setProvider('');
    setTreasury('');
    setMinLevel('');
    setMaxLevel('');
    setLevelOwnerProfitt(0);
    setProviderProfit(0);
    setThirdPartyProfit(0);
    setAffiliateProfit(0);
    setIsExternal(false);
    setIsStoshi(false);
    setTags([]);
    setScoringModel(undefined);
  };

  const { loading: playerLoading } = useGetPlayer(null, false);
  const { playerInfo } = useAppSelector(state => state.player);

  const isPlatformAdmin = useMemo(() => {
    return playerInfo?.roles?.some(
      role => role === PlayerRoles.SuperAdmin || role === PlayerRoles.Admin,
    );
  }, [playerInfo]);
  const isInWhitelist = useMemo(() => {
    return playerInfo?.roles?.includes(PlayerRoles.Provider);
  }, [playerInfo]);

  const platformProfit = useMemo(() => {
    return (
      100 -
      levelOwnerProfit -
      ((isPlatformAdmin ? isExternal : true) ? providerProfit : 0) -
      (isStoshi ? thirdPartyProfit : 0) -
      affiliateProfit
    );
  }, [
    levelOwnerProfit,
    providerProfit,
    thirdPartyProfit,
    isExternal,
    isStoshi,
    isPlatformAdmin,
    affiliateProfit,
  ]);

  const integrateInfo = useMemo(() => {
    const _isExternal = isPlatformAdmin ? isExternal : true;

    let croppedBackgroundImg;
    if (backgroundImg && savedBackgroundImg) {
      const blob = dataURLToBlob(savedBackgroundImg);
      const { type, name, uid, lastModifiedDate } = backgroundImg as UploadFile;
      croppedBackgroundImg = new File([blob as BlobPart], name, {
        type,
        lastModified: lastModifiedDate?.getTime(),
      });
      Object.assign(croppedBackgroundImg, { uid });
      Object.assign(croppedBackgroundImg, { url: savedBackgroundImg });
      Object.assign(croppedBackgroundImg, { preview: savedBackgroundImg });
      Object.assign(croppedBackgroundImg, { thumbUrl: savedBackgroundImg });
    }

    const info: IntegrateInfo = {
      name: gameName,
      description,
      slug,
      activeVersion: gameVersion,
      defaultEntryFee: entryFee,
      token,
      isExternal: _isExternal,
      provider: _isExternal
        ? {
            username: providerExists?.player?.username,
            avatarURL: providerExists?.player?.avatarURL,
          }
        : undefined,
      isStoshi: _isExternal ? false : isStoshi,
      treasury: isStoshi
        ? {
            username: treasuryExists?.player?.username,
            avatarURL: treasuryExists?.player?.avatarURL,
          }
        : undefined,
      affiliateFeeRate: affiliateProfit ? affiliateProfit : undefined,
      treasuryFeeRate: isStoshi ? thirdPartyProfit : undefined,
      providerFeeRate: _isExternal ? providerProfit : undefined,
      levelOwnerFeeRate: levelOwnerProfit,
      platformFeeRate: platformProfit,
      isInWhitelist,
      minLevel: minLevel ? Number(minLevel) : undefined,
      maxLevel: maxLevel ? Number(maxLevel) : undefined,
      backgroundImg: croppedBackgroundImg as UploadFileDTO,
      buildFile,
      isPlatformAdmin,
      tags,
      scoringModel,
    };
    return info;
  }, [
    isPlatformAdmin,
    isExternal,
    backgroundImg,
    savedBackgroundImg,
    gameName,
    description,
    slug,
    gameVersion,
    entryFee,
    token,
    isStoshi,
    affiliateProfit,
    thirdPartyProfit,
    providerProfit,
    levelOwnerProfit,
    platformProfit,
    isInWhitelist,
    minLevel,
    maxLevel,
    buildFile,
    tags,
    scoringModel,
    providerExists,
    treasuryExists,
  ]);

  const {
    isAvailable: isSlugAvailable,
    loading: isCheckingSlug,
    checkedValue: checkedSlug,
  } = useCheckGamePathAvailable(slug);

  const ableToReview = useMemo(() => {
    const isValid =
      gameName &&
      gameVersion &&
      description &&
      slug &&
      token &&
      platformProfit &&
      levelOwnerProfit &&
      entryFee &&
      backgroundImg &&
      buildFile &&
      affiliateProfit &&
      Number(platformProfit) > 0 &&
      Number(platformProfit) <= 100 &&
      Number(levelOwnerProfit) > 0 &&
      Number(levelOwnerProfit) <= 100 &&
      Number(affiliateProfit) > 0 &&
      Number(affiliateProfit) <= 100 &&
      Number(entryFee) >= 0 &&
      isSlugAvailable &&
      isSlugAvailable.isAvailable &&
      !isCheckingSlug &&
      slug === checkedSlug &&
      scoringModel &&
      minLevel &&
      maxLevel &&
      Number(minLevel) >= 0 &&
      Number(maxLevel) >= 0 &&
      Number(minLevel) <= Number(maxLevel);
    if (isExternal || (isInWhitelist && !isPlatformAdmin)) {
      return (
        isValid &&
        provider &&
        !checkingProvider &&
        providerExists &&
        providerExists.player?.username === provider &&
        providerProfit &&
        Number(providerProfit) > 0 &&
        Number(providerProfit) <= 100
      );
    } else {
      if (isStoshi) {
        return (
          isValid &&
          thirdPartyProfit &&
          treasury &&
          !checkingTreasury &&
          treasuryExists &&
          treasuryExists.player?.username === treasury &&
          Number(thirdPartyProfit) > 0 &&
          Number(thirdPartyProfit) <= 100
        );
      }
      return isValid;
    }
  }, [
    gameName,
    gameVersion,
    description,
    slug,
    token,
    platformProfit,
    levelOwnerProfit,
    entryFee,
    backgroundImg,
    buildFile,
    affiliateProfit,
    isSlugAvailable,
    isCheckingSlug,
    checkedSlug,
    scoringModel,
    minLevel,
    maxLevel,
    isExternal,
    isInWhitelist,
    isPlatformAdmin,
    provider,
    checkingProvider,
    providerExists,
    providerProfit,
    isStoshi,
    thirdPartyProfit,
    treasury,
    checkingTreasury,
    treasuryExists,
  ]);

  const handleSelectTokenScroll = e => {
    if (
      e.currentTarget.scrollTop + e.currentTarget.offsetHeight >=
      e.currentTarget.scrollHeight
    ) {
      setScrollToken(!scrollToken);
    }
  };

  const handleEditClick = e => {
    if (backgroundImg && !savedBackgroundImg) {
      e.stopPropagation();
      setVisible(true);
    }
  };

  useEffect(() => {
    if (backgroundImg && !visible) {
      setVisible(true);
    }
  }, [backgroundImg]);

  const handleCancel = () => {
    if (backgroundImg && !savedBackgroundImg) {
      handleBackgroundImg();
    }
    setVisible(false);
  };

  const handleSave = () => {
    const canvasScaled = inputRef?.current?.cropper?.getCroppedCanvas();
    const dataURL = canvasScaled?.toDataURL();
    if (dataURL) {
      setSavedBackgroundImg(dataURL);
    } else {
      toast.error('Edit image failed. Please upload and edit again');
      handleBackgroundImg();
    }

    setVisible(false);
  };

  const getBase64 = file => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => resolve(e.target ? e.target.result : null);
      reader.onerror = error => reject(error);
    });
  };

  const uploadImageProps = {
    name: 'BackgroundImg',
    multiple: false,
    beforeUpload: async (_, fileList) => {
      handleBackgroundImg();

      const files = [];
      for (const file of fileList) {
        const slip = file;
        if (slip && !slip.url && !slip.preview) {
          const preview = (await getBase64(slip))?.toString() || '';
          slip.preview = preview;
          slip.url = preview;
          slip.thumbUrl = preview;
          setPreviewBackgroundImg(preview);
        }
        files.push(slip);
      }
      setBackgroundImg(files[0]);
      form.setFieldValue('BackgroundImg', files);
      await form.validateFields();
      return false;
    },
    fileList: backgroundImg ? [backgroundImg] : [],
    accept: 'image/jpg,image/jpeg,image/png,image/JPG,image/JPEG,image/PNG',
    onPreview: async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file);
      }
      setPreviewBackgroundImg(file.preview);
    },
    showUploadList: false,
    maxCount: 1,
  };

  const uploadFileZipProps = {
    name: 'FileZip',
    multiple: false,
    beforeUpload: async file => {
      setBuildFile(file);
      form.setFieldValue('FileZip', [file]);
      await form.validateFields();
      return false;
    },
    fileList: buildFile ? [buildFile] : [],
    accept: '.zip,application/zip,application/x-zip-compressed',
    showUploadList: false,
    maxCount: 1,
  };

  const handleBackgroundImg = () => {
    setBackgroundImg(undefined);
    setPreviewBackgroundImg(undefined);
    setSavedBackgroundImg(undefined);
    form.setFieldValue('BackgroundImg', []);
    form.validateFields();
  };

  const handleFileZip = () => {
    setBuildFile(undefined);
    form.setFieldValue('FileZip', []);
    form.validateFields();
  };

  const levelBasedOptions = scoringModeList.filter(mode =>
    mode.includes('LevelPass'),
  );

  const rankingBasedOptions = scoringModeList.filter(
    mode => !levelBasedOptions.includes(mode),
  );
  const validateInput = value => {
    const regex = /^[0-9-.]*$/;
    return regex.test(value);
  };

  return (
    <>
      <ThemedModal
        title={'Register Your Game'}
        open={isOpen}
        onCancel={onCloseModal}
        footer={false}
        width={870}
      >
        <StyledForm
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
          labelcolor="black"
          form={form}
        >
          <FormItemWrapper>
            <GroupTitleStyled>Game Info</GroupTitleStyled>
            <Row gutter={16}>
              <Col xs={24} sm={24} md={12} lg={12}>
                <FormItemInput className="mt-2" name={['game-name']}>
                  <InputWrapper>
                    <LabelStyled>Game name</LabelStyled>
                    <InputField
                      allowClear
                      type={'string'}
                      placeholder="Enter here"
                      onChange={e => setGameName(e.target.value)}
                      disabled={loading}
                      value={gameName}
                    />
                  </InputWrapper>
                </FormItemInput>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <FormItemInput
                  className="mt-2"
                  name={['game-slug']}
                  rules={[
                    {
                      validator: (_, value, callback) => {
                        if (value && !/^[a-zA-Z]/.test(value)) {
                          callback('Game path must start with a letter.');
                        } else {
                          callback();
                        }
                      },
                    },
                    {
                      validator: (_, value, callback) => {
                        if (value && !/^[a-z0-9-]+$/.test(value)) {
                          callback(
                            'Allowed characters are a-z (only lower case), 0-9, and -(dash).',
                          );
                        } else {
                          callback();
                        }
                      },
                    },
                  ]}
                  validateFirst={true}
                >
                  <InputWrapper>
                    <LabelStyled>Game path</LabelStyled>
                    <Spin spinning={isCheckingSlug}>
                      <InputField
                        allowClear
                        type={'string'}
                        placeholder="Enter here"
                        onChange={e => setSlug(e.target.value)}
                        disabled={loading}
                      />
                    </Spin>
                  </InputWrapper>
                </FormItemInput>
                {slug && slug === checkedSlug && !isCheckingSlug ? (
                  !isSlugAvailable ||
                  typeof isSlugAvailable?.isAvailable !== 'boolean' ? (
                    ''
                  ) : !isSlugAvailable.isAvailable ? (
                    <WarningStyled>**Game path already exists.</WarningStyled>
                  ) : (
                    <SuccessStyled>**Game path is available</SuccessStyled>
                  )
                ) : null}
              </Col>
              <Col xs={24}>
                <FormItemInput className="mt-2" name={['game-description']}>
                  <InputWrapper>
                    <LabelStyled>Description</LabelStyled>
                    <StyledTextArea
                      allowClear
                      placeholder="Enter here"
                      onChange={e => {
                        setDescription(JSON.stringify(e.target.value));
                        console.log(JSON.stringify(e.target.value));
                      }}
                      disabled={loading}
                    />
                  </InputWrapper>
                </FormItemInput>
                <FormItemInput className="mt-2" name={['scoringModel']}>
                  <InputWrapper>
                    <LabelStyled>Scoring model</LabelStyled>
                    <SelectStyled
                      placeholder="Select here"
                      allowClear
                      onChange={(value: string) => setScoringModel(value)}
                      disabled={loading}
                      options={[
                        {
                          label: 'Level based',
                          options: levelBasedOptions.map(model => ({
                            label: model.replace(/([A-Z])/g, ' $1').slice(1),
                            value: model,
                          })),
                        },
                        {
                          label: 'Ranking based',
                          options: rankingBasedOptions.map(model => ({
                            label: model.replace(/([A-Z])/g, ' $1').slice(1),
                            value: model,
                          })),
                        },
                      ]}
                      loading={scoringModelLoading}
                      value={scoringModel}
                    />
                  </InputWrapper>
                </FormItemInput>
                <FormItemInput className="mt-2" name={['game-tags']}>
                  <InputWrapper>
                    <LabelStyled>Tags</LabelStyled>
                    <SelectStyled
                      placeholder="Enter here"
                      disabled={loading}
                      mode={'tags'}
                      onSelect={(value: string) => {
                        setTags([...tags, value]);
                      }}
                      onDeselect={value => {
                        setTags(tags.filter(i => i !== value));
                      }}
                    />
                  </InputWrapper>
                </FormItemInput>
                <FormItemInput className="mt-2" name={['game-cover-img']}>
                  <InputWrapper
                    style={{
                      justifyContent: 'space-between',
                      maxHeight: 'unset',
                    }}
                  >
                    <LabelStyled>Background image</LabelStyled>
                    <UploadDragger {...uploadImageProps}>
                      {backgroundImg ? (
                        <BackgroundGameImage
                          onClick={handleEditClick}
                          src={savedBackgroundImg}
                        >
                          <CloseCircleOutlined
                            onClick={e => {
                              e.stopPropagation();
                              handleBackgroundImg();
                            }}
                          />
                        </BackgroundGameImage>
                      ) : (
                        <UploadText onClick={handleEditClick}>
                          Upload here
                        </UploadText>
                      )}
                    </UploadDragger>
                  </InputWrapper>
                  <Modal
                    title="Edit background image"
                    visible={visible}
                    onCancel={handleCancel}
                    onOk={handleSave}
                    okText="Save"
                    cancelText="Cancel"
                    bodyStyle={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    {/* <AvatarEditor
                      ref={inputRef}
                      image={savedBackgroundImg || previewBackgroundImg}
                      width={160}
                      height={200}
                      border={50}
                      color={[255, 255, 255, 0.6]}
                      scale={1}
                      rotate={0}
                    /> */}
                    <ImageCropperWrapper>
                      <Cropper
                        ref={inputRef}
                        aspectRatio={8 / 10}
                        src={savedBackgroundImg || previewBackgroundImg}
                        viewMode={1}
                        background={false}
                        responsive={true}
                        autoCropArea={1}
                        checkOrientation={false}
                        guides={false}
                        dragMode={'move'}
                        checkCrossOrigin={false}
                        center={false}
                        highlight={false}
                        cropBoxResizable={false}
                        zoomable={false}
                      />
                    </ImageCropperWrapper>
                  </Modal>
                </FormItemInput>
                <FormItemInput className="mt-2" name={['game-build-file-url']}>
                  <InputWrapper style={{ justifyContent: 'space-between' }}>
                    <LabelStyled>Build file</LabelStyled>
                    <UploadDragger {...uploadFileZipProps}>
                      {buildFile ? (
                        <UploadFileZipWrapper>
                          {buildFile?.name}
                          <CloseCircleOutlined
                            onClick={e => {
                              e.stopPropagation();
                              handleFileZip();
                            }}
                          />
                        </UploadFileZipWrapper>
                      ) : (
                        <UploadText>Upload here</UploadText>
                      )}
                    </UploadDragger>
                  </InputWrapper>
                </FormItemInput>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <FormItemInput className="mt-2" name={['min-level']}>
                  <InputWrapper>
                    <LabelStyled>Min Level</LabelStyled>
                    <InputField
                      allowClear
                      type={'number'}
                      min={0}
                      placeholder="Enter here"
                      onChange={e => setMinLevel(e.target.value)}
                      disabled={loading}
                      onKeyDown={handleKeyDown}
                      value={minLevel || ''}
                    />
                  </InputWrapper>
                </FormItemInput>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12}>
                <FormItemInput className="mt-2" name={['max-level']}>
                  <InputWrapper>
                    <LabelStyled>Max Level</LabelStyled>
                    <InputField
                      allowClear
                      type={'number'}
                      min={0}
                      placeholder="Enter here"
                      onChange={e => setMaxLevel(e.target.value)}
                      disabled={loading}
                      onKeyDown={handleKeyDown}
                      value={maxLevel || ''}
                    />
                  </InputWrapper>
                </FormItemInput>
                {minLevel &&
                  maxLevel &&
                  Number(minLevel) > Number(maxLevel) && (
                    <WarningStyled>
                      **Max Level cannot be less than Min Level
                    </WarningStyled>
                  )}
              </Col>
            </Row>
            <>
              <DividerStyled />
              <GroupTitleStyled>Game Release</GroupTitleStyled>
              <Row gutter={[8, 8]} className="mt-2">
                <InputWrapper>
                  <LabelStyled>Game version</LabelStyled>
                  <InputField
                    allowClear
                    type="text"
                    placeholder="Ex: 1.0.0"
                    onChange={e => {
                      const { value } = e.target;
                      if (validateInput(value)) {
                        setGameVersion(value);
                      }
                    }}
                    disabled={loading}
                    value={gameVersion}
                  />
                </InputWrapper>
              </Row>
            </>
            {isPlatformAdmin && (
              <>
                <DividerStyled />
                <GroupTitleStyled>Game Type</GroupTitleStyled>
                <Row gutter={[8, 8]} className="mt-2">
                  <Col xs={24}>
                    <CheckBoxWrapper
                      param={!isExternal ? (!isExternal).toString() : undefined}
                    >
                      <CheckBoxText>Internal game</CheckBoxText>
                      <CheckBoxStyled
                        param={
                          !isExternal ? (!isExternal).toString() : undefined
                        }
                        checked={!isExternal}
                        value={GameType.internal}
                        onChange={() => {
                          handleExternalChange(GameType.internal);
                          // setOpenKeys(['internal']);
                        }}
                      />
                    </CheckBoxWrapper>
                    <RadioGroupStyled
                      value={isStoshi}
                      onChange={() => handleStoshiChange(!isStoshi)}
                      disabled={isExternal}
                    >
                      <Radio value={false}>General game</Radio>
                      <Radio value={true}>Third party game</Radio>
                    </RadioGroupStyled>
                    <CheckBoxWrapper
                      param={isExternal ? isExternal.toString() : undefined}
                    >
                      <CheckBoxText>External game</CheckBoxText>
                      <CheckBoxStyled
                        param={isExternal ? isExternal.toString() : undefined}
                        checked={isExternal}
                        value={GameType.external}
                        onChange={() => {
                          handleExternalChange(GameType.external);
                          // setOpenKeys(['external']);
                        }}
                      />
                    </CheckBoxWrapper>
                  </Col>
                </Row>
              </>
            )}
            <DividerStyled />
            <GroupTitleStyled>Distribution Config</GroupTitleStyled>
            <Row gutter={8}>
              <Col xs={24} sm={24} md={24} lg={14}>
                <FormItemInput className="mt-2" name={['token']}>
                  <InputWrapper>
                    <LabelStyled>Tokens used</LabelStyled>
                    <SelectStyled
                      placeholder="Select here"
                      allowClear
                      onChange={value => handleTokenChange(value)}
                      disabled={loading}
                      options={listToken.map(({ name, symbol, address }) => ({
                        label: `${name} (${symbol})`,
                        value: address,
                      }))}
                      loading={listTokenLoading}
                      onPopupScroll={handleSelectTokenScroll}
                      value={token?.address}
                    />
                  </InputWrapper>
                </FormItemInput>
                {(isExternal || (isInWhitelist && !isPlatformAdmin)) && (
                  <>
                    <FormItemInput className="mt-2" name={['provider']}>
                      <InputWrapper>
                        <LabelStyled>Provider username</LabelStyled>
                        <Spin spinning={checkingProvider}>
                          <InputField
                            allowClear
                            type={'string'}
                            placeholder="Enter here"
                            onChange={e => setProvider(e.target.value)}
                            disabled={loading}
                            value={provider}
                          />
                        </Spin>
                      </InputWrapper>
                    </FormItemInput>
                    {provider &&
                      !checkingProvider &&
                      providerExists &&
                      !providerExists?.isExisting && (
                        <WarningStyled>
                          **Username does not exists
                        </WarningStyled>
                      )}
                  </>
                )}
                {isStoshi && (
                  <>
                    <FormItemInput className="mt-2" name={['treasury']}>
                      <InputWrapper>
                        <LabelStyled>Third party username</LabelStyled>
                        <Spin spinning={checkingTreasury}>
                          <InputField
                            allowClear
                            type={'string'}
                            placeholder="Enter here"
                            onChange={e => setTreasury(e.target.value)}
                            disabled={loading}
                            value={treasury}
                          />
                        </Spin>
                      </InputWrapper>
                    </FormItemInput>
                    {treasury &&
                      !checkingTreasury &&
                      treasuryExists &&
                      !treasuryExists?.isExisting && (
                        <WarningStyled>
                          **Username does not exists
                        </WarningStyled>
                      )}
                  </>
                )}
                <FormItemInput className="mt-2" name={['default-fee']}>
                  <InputWrapper>
                    <LabelStyled>Default entry fee</LabelStyled>
                    <InputField
                      allowClear
                      type={'number'}
                      placeholder="0"
                      min={0}
                      step={0.00001}
                      onChange={e => handleEntryFeeChange(e.target.value)}
                      disabled={loading}
                      value={entryFee}
                    />
                  </InputWrapper>
                </FormItemInput>
                {entryFee && Number(entryFee) < 0 && (
                  <WarningStyled>
                    **Default entry fee must be greater than or equal to 0
                  </WarningStyled>
                )}
              </Col>
              <Col xs={24} sm={24} md={24} lg={10}>
                <FormItemInput className="mt-2" name={['level-owner-profit']}>
                  <InputWrapper>
                    <LabelStyled>Level owner profit</LabelStyled>
                    <InputField
                      allowClear
                      type={'number'}
                      placeholder="0%"
                      min={0}
                      max={100}
                      step={0.01}
                      onChange={e =>
                        handleLevelOwnerProfitChange(e.target.value)
                      }
                      disabled={loading}
                      value={levelOwnerProfit || ''}
                    />
                  </InputWrapper>
                </FormItemInput>
                {isStoshi && (
                  <>
                    <FormItemInput className="mt-2" name={['treasury-profit']}>
                      <InputWrapper>
                        <LabelStyled>Third-party profit</LabelStyled>
                        <InputField
                          allowClear
                          type={'number'}
                          placeholder="0%"
                          min={0}
                          max={100}
                          step={0.01}
                          onChange={e =>
                            handleTreasuryProfitChange(e.target.value)
                          }
                          disabled={loading}
                          value={thirdPartyProfit || ''}
                        />
                      </InputWrapper>
                    </FormItemInput>
                    {!!thirdPartyProfit &&
                      (Number(thirdPartyProfit) <= 0 ||
                        Number(thirdPartyProfit) > 100) && (
                        <WarningStyled>
                          **Third party profit must be greater than 0 and less
                          than 100
                        </WarningStyled>
                      )}
                  </>
                )}
                <FormItemInput className="mt-2" name={['affiliate-profit']}>
                  <InputWrapper>
                    <LabelStyled>Affiliate profit</LabelStyled>
                    <InputField
                      allowClear
                      type={'number'}
                      placeholder="0%"
                      min={0}
                      max={100}
                      step={0.01}
                      onChange={e =>
                        handleAffiliateProfitChange(e.target.value)
                      }
                      disabled={loading}
                      value={affiliateProfit || ''}
                    />
                  </InputWrapper>
                </FormItemInput>
                {!!affiliateProfit &&
                  (Number(affiliateProfit) <= 0 ||
                    Number(affiliateProfit) > 100) && (
                    <WarningStyled>
                      **Affiliate profit must be greater than 0 and less than
                      100
                    </WarningStyled>
                  )}
                {(isExternal || (isInWhitelist && !isPlatformAdmin)) && (
                  <>
                    <FormItemInput className="mt-2" name={['provider-profit']}>
                      <InputWrapper>
                        <LabelStyled>Provider profit</LabelStyled>
                        <InputField
                          allowClear
                          type={'number'}
                          placeholder="0%"
                          min={0}
                          max={100}
                          step={0.01}
                          onChange={e =>
                            handleProviderProfitChange(e.target.value)
                          }
                          disabled={loading}
                          value={providerProfit || ''}
                        />
                      </InputWrapper>
                    </FormItemInput>
                    {!!providerProfit &&
                      (Number(providerProfit) <= 0 ||
                        Number(providerProfit) > 100) && (
                        <WarningStyled>
                          **Provider profit must be greater than 0 and less than
                          100
                        </WarningStyled>
                      )}
                  </>
                )}
                <FormItemInput className="mt-2" name={['platform-profit']}>
                  <InputWrapper>
                    <LabelStyled>Platform profit</LabelStyled>
                    <TextField flex="1">{platformProfit || 0}%</TextField>
                  </InputWrapper>
                </FormItemInput>
              </Col>
            </Row>
          </FormItemWrapper>

          <Wrapper>
            {loading ? (
              <Spin />
            ) : (
              <SubmitButton
                type="primary"
                htmlType="submit"
                disabled={!ableToReview}
              >
                Review
              </SubmitButton>
            )}
          </Wrapper>
        </StyledForm>
      </ThemedModal>
      <ReviewIntegrate
        {...{
          ...integrateInfo,
          backgroundColor:
            form.getFieldValue('game-background-color') || '#000000',
          logoColor: form.getFieldValue('game-logo-color') || '#000000',
        }}
        onRefresh={() => onRefreshSuccess()}
      />
    </>
  );
};
