import axios, { AxiosInstance, Method } from 'axios';
import queryString from 'query-string';
import { loginUser, logoutUser } from 'redux/slices/player';
import store from 'redux/store';
import { LocalhostStorage } from 'utils/sessionStorage';

import { refreshToken } from './api';
export type IRequestParam = {
  method: Method;
  data: any;
  endpoint: string;
};

export class HttpService {
  private readonly http: AxiosInstance;

  constructor(baseUrl: string) {
    const instance = axios.create({
      baseURL: baseUrl,
      headers: {
        'content-type': 'application/json',
        'Access-Control-Expose-Headers': 'Content-Disposition',
      },
      paramsSerializer: params => queryString.stringify(params),
      // withCredentials: true,
    });

    instance.interceptors.request.use(
      async config => {
        const token = LocalhostStorage.get('accessToken');
        const tokenType = LocalhostStorage.get('tokenType');

        if (token) {
          config.headers = {
            Authorization: `Bearer ${token}`,
            Type: `${tokenType}`,
          };
        }
        return config;
      },
      error => Promise.reject(error),
    );

    instance.interceptors.response.use(
      response => {
        // if (response.data) {
        //   return response.data;
        // }
        return response;
      },
      async error => {
        const err =
          (error.response && error.response.data && error.response.data) ||
          error;

        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const { dispatch } = store;
          const token = LocalhostStorage.get('refreshToken');
          if (token) {
            const res = await refreshToken({ refreshToken: token });
            if (res?.success) {
              LocalhostStorage.set(
                'accessToken',
                res?.payload?.data?.accessToken,
              );
              dispatch(loginUser(res?.payload?.data));

              prevRequest.headers[
                'Authorization'
              ] = `Bearer ${res?.payload?.data?.accessToken}`;
              return this.http(prevRequest);
            }
          }
          LocalhostStorage.remove('accessToken');
          LocalhostStorage.remove('refreshToken');
          LocalhostStorage.remove('tokenType');
          dispatch(logoutUser());
        }
        return Promise.reject(err); // Propagate rejection back to caller
      },
    );
    this.http = instance;
  }

  request = async ({ method, data = {}, endpoint = '/' }: IRequestParam) => {
    try {
      const res = await this.http[method](endpoint, data);
      const final = {
        success: res.data.success,
        payload: {
          data: res.data.data,
          totalCount: res.data.totalCount,
          pageSize: res.data.pageSize,
        },
        message: res.data.message as string,
      };
      return final;
    } catch (e) {
      return {
        success: false,
        message: e.message as string,
        payload: null,
      };
    }
  };

  download = async ({ method, data = {}, endpoint = '/' }: IRequestParam) => {
    try {
      const res = await this.http[method](endpoint, data, {
        responseType: 'blob',
        headers: {
          'Access-Control-Expose-Headers': 'Content-Disposition',
        },
      });
      return res;
    } catch (e) {
      return {
        statusCode: false,
        message: e.message as string,
        payload: null,
      };
    }
  };
}

export default new HttpService(process.env.REACT_APP_API);
