import { PlayerRoles } from 'constants/enum/PlayerRole';
import { RegisterStep } from 'constants/enum/RegisterStep';
import { PitRouter } from 'constants/routers';
import useEnableCashier from 'hooks/cashier/useEnableCashier';
// import { ActionButtonTest } from 'pages/Action';
import { AdminPanel } from 'pages/AdminPanel';
import { AdminStats } from 'pages/AdminStats';
import { Affiliate } from 'pages/Affiliate';
import { Cashier } from 'pages/Cashier';
import { ResetPassword } from 'pages/ForgotPassword';
// import { Dashboard } from 'pages/Dashboard';
// import { FinishLevel } from 'pages/FinishLevel';
import { GameList } from 'pages/GameList';
import { GamesPage } from 'pages/Games';
// import { HomePage } from 'pages/HomePage';
import { LandingPage } from 'pages/LandingPage';
import { LeaderBoard } from 'pages/Leaderboard';
import { LoginPage } from 'pages/Login';
import { PlayGame } from 'pages/PlayGame';
import { PosterPage } from 'pages/Poster';
import { Profile } from 'pages/Profile';
import { SignUpAccount } from 'pages/Register';
// import { UserRegistered } from 'pages/Wallet';
import { Suspense } from 'react';
import ReactGA from 'react-ga4';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Layout from 'template/Layout';

import { usePublicPermission } from '../hooks/usePublicPermission';
import { SupportPage } from '../pages/Support';
import MaintenanceRoute from './MaintenanceRoute';
import { PassCodeRoute } from './PassCodeRoute';
import { PrivateRoute } from './PrivateRoute';
import { RoleRoute } from './RoleRoute';

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TAG_ID);

export const AppRouter = () => {
  const isPublicPermission = usePublicPermission();
  const isEnableCashier = useEnableCashier();
  return (
    <Router basename="/">
      <Suspense fallback={<LandingPage />}>
        <Routes>
          <Route element={<MaintenanceRoute />}>
            <Route path={PitRouter.HOME} element={<LandingPage />} />
            {/* <Route
              path={PitRouter.CONNECT}
              element={
                <PassCodeRoute>
                  <HomePage />
                </PassCodeRoute>
              }
            /> */}
            <Route
              path={PitRouter.REGISTER}
              element={
                <PassCodeRoute>
                  <SignUpAccount defaultStep={RegisterStep.enterDetail} />
                </PassCodeRoute>
              }
            />
            <Route
              path={PitRouter.AFFILIATE_CODE}
              element={
                <PassCodeRoute>
                  <SignUpAccount defaultStep={RegisterStep.enterDetail} />
                </PassCodeRoute>
              }
            />
            <Route
              path={PitRouter.LOGIN}
              element={
                <PassCodeRoute>
                  <LoginPage />
                </PassCodeRoute>
              }
            />
            <Route
              path={PitRouter.FORGOT_PASSWORD}
              element={
                <PassCodeRoute>
                  <ResetPassword />
                </PassCodeRoute>
              }
            />
            {isPublicPermission ? (
              <Route path={PitRouter.GAME_LIST} element={<GamesPage />} />
            ) : (
              <></>
            )}
            {/* <Route
              path={PitRouter.USER}
              element={
                <PrivateRoute>
                  <UserRegistered />
                </PrivateRoute>
              }
            /> */}
          </Route>
          {/* <Route
            path="*"
            element={
              // <PrivateRoute>
              <GamesPage />
              // </PrivateRoute>
            }
          /> */}
          <Route element={<Layout />}>
            {/* <Layout> */}
            <Route
              path={PitRouter.LEADER_BOARD}
              element={
                <PassCodeRoute>
                  <PrivateRoute>
                    <LeaderBoard />
                  </PrivateRoute>
                </PassCodeRoute>
              }
            />
            <Route
              path={PitRouter.GAME_LIST}
              element={
                <PassCodeRoute>
                  <PrivateRoute>
                    <GamesPage />
                  </PrivateRoute>
                </PassCodeRoute>
              }
            />
            <Route
              path={PitRouter.MANAGEMENT}
              element={
                <PassCodeRoute>
                  <PrivateRoute>
                    <RoleRoute
                      roles={[
                        PlayerRoles.SuperAdmin,
                        PlayerRoles.Admin,
                        PlayerRoles.Provider,
                      ]}
                    >
                      <GameList />
                    </RoleRoute>
                  </PrivateRoute>
                </PassCodeRoute>
              }
            />
            <Route
              path={PitRouter.ADMIN_PANEL}
              element={
                <PassCodeRoute>
                  <PrivateRoute>
                    <RoleRoute
                      roles={[PlayerRoles.SuperAdmin, PlayerRoles.Admin]}
                    >
                      <AdminPanel />
                    </RoleRoute>
                  </PrivateRoute>
                </PassCodeRoute>
              }
            />
            <Route
              path={PitRouter.ADMIN_STATS}
              element={
                <PassCodeRoute>
                  <PrivateRoute>
                    <RoleRoute
                      roles={[PlayerRoles.SuperAdmin, PlayerRoles.Admin]}
                    >
                      <AdminStats />
                    </RoleRoute>
                  </PrivateRoute>
                </PassCodeRoute>
              }
            />
            <Route
              path={PitRouter.POSTER}
              element={
                <PassCodeRoute>
                  <PrivateRoute>
                    <PosterPage />
                  </PrivateRoute>
                </PassCodeRoute>
              }
            />
            <Route
              path={PitRouter.PLAY_GAME}
              element={
                <PassCodeRoute>
                  <PrivateRoute>
                    <PlayGame />
                  </PrivateRoute>
                </PassCodeRoute>
              }
            />
            <Route
              path={PitRouter.PROFILE}
              element={
                <PassCodeRoute>
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                </PassCodeRoute>
              }
            />
            {isEnableCashier && (
              <Route
                path={PitRouter.CASHIER}
                element={
                  <PassCodeRoute>
                    <PrivateRoute>
                      <Cashier />
                    </PrivateRoute>
                  </PassCodeRoute>
                }
              />
            )}
            <Route
              path={PitRouter.SUPPORT}
              element={
                <PassCodeRoute>
                  <PrivateRoute>
                    <SupportPage />
                  </PrivateRoute>
                </PassCodeRoute>
              }
            />
            <Route
              path={PitRouter.AFFILIATE}
              element={
                <PassCodeRoute>
                  <PrivateRoute>
                    <RoleRoute roles={[PlayerRoles.Influencer]}>
                      <Affiliate />
                    </RoleRoute>
                  </PrivateRoute>
                </PassCodeRoute>
              }
            />
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
};
