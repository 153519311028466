import biga from 'assets/vectors/logo-landingPage-biga.svg';
import scrollDown from 'assets/vectors/scroll-down.svg';
import { SplashVideoFullScreen } from 'components/AccessControl/styled';
import { LandingFooterSection } from 'components/Landing/FooterSection';
import { LandingGamesSection } from 'components/Landing/GamesSection';
import { LandingHeadingSection } from 'components/Landing/HeadingSection';
import { LandingJoinWaitListButton } from 'components/Landing/JoinWaitListButton';
import { JoinWaitListSection } from 'components/Landing/JoinWaitListSection';
import { ListSocial } from 'components/Landing/ListSocial';
import { LandingPartnersSection } from 'components/Landing/PartnersSection';
import { TopEarners } from 'components/Landing/TopEarners';
import { LandingVideoSection } from 'components/Landing/VideoSection';
import { PitRouter } from 'constants/routers';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LandingDeveloperSection } from '../../components/Landing/DeveloperSection';
import {
  Description,
  FirstBlockLeftTopLandingPageWrapper,
  FirstBlockRightTopLandingPageWrapper,
  FirstBlockTopLandingPageWrapper,
  FooterDescription,
  LandingContentWrapper,
  LandingPageFixedWrapper,
  LandingPageTopScreenWrapper,
  LandingPageWrapper,
  LandingScrollDownText,
  LandingScrollDownWrapper,
  Logo,
  MainBlockTopLandingPageWrapper,
  ScrollImage,
  SecondBlockTopLandingPageWrapper,
} from './styled';

export const LandingPage = () => {
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState<boolean>(false);

  return (
    <LandingPageWrapper>
      <LandingPageFixedWrapper>
        <LandingPageTopScreenWrapper>
          <MainBlockTopLandingPageWrapper>
            <FirstBlockTopLandingPageWrapper>
              <FirstBlockLeftTopLandingPageWrapper>
                <Logo src={biga} alt="logo" />
                <Description>
                  The earn arcade for gamers who want to make money
                </Description>
              </FirstBlockLeftTopLandingPageWrapper>
              <FirstBlockRightTopLandingPageWrapper>
                <LandingJoinWaitListButton />
                <TopEarners />
              </FirstBlockRightTopLandingPageWrapper>
            </FirstBlockTopLandingPageWrapper>

            <SecondBlockTopLandingPageWrapper>
              <ListSocial />

              <LandingScrollDownWrapper>
                <ScrollImage src={scrollDown} alt="scroll-down" />
                <LandingScrollDownText>Scroll down</LandingScrollDownText>
              </LandingScrollDownWrapper>

              <FooterDescription>
                MAKE AN INCOME PLAYING GAMES!
              </FooterDescription>
            </SecondBlockTopLandingPageWrapper>
            {showVideo && (
              <SplashVideoFullScreen
                onEnded={() => {
                  navigate(PitRouter.REGISTER);
                  setShowVideo(false);
                }}
                className="splash-video-full-screen"
              />
            )}
          </MainBlockTopLandingPageWrapper>
        </LandingPageTopScreenWrapper>
      </LandingPageFixedWrapper>
      <LandingContentWrapper>
        <LandingHeadingSection />

        <LandingVideoSection />

        <JoinWaitListSection />

        <LandingGamesSection />

        <LandingPartnersSection />

        <LandingDeveloperSection />

        <LandingFooterSection />
      </LandingContentWrapper>
    </LandingPageWrapper>
  );
};
