import { LandingWhitepaperButtonWrapper } from './styled';

export const WhitePaperButton = () => {
  const handleOpenPdf = () => {
    const pdfUrl = `${process.env.REACT_APP_HOST}/whitepaper.pdf`;
    window.open(pdfUrl, '_blank');
  };

  return (
    <LandingWhitepaperButtonWrapper onClick={() => handleOpenPdf()}>
      <div>
        <span>White paper</span>
      </div>
    </LandingWhitepaperButtonWrapper>
  );
};
