import playButtonImg from 'assets/vectors/game-video-play-button.svg';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  LandingIframe,
  LandingIframeWrapper,
  LandingPreIframe,
  LandingVideoPlayButton,
  LandingVideoPlayLinkButton,
  LandingVideoPlayWrapper,
  LandingVideoSectionWrapper,
  LandingVideoWrapper,
} from './styled';

export const LandingVideoSection = () => {
  const [player, setPlayer] = useState<{ playVideo: () => void }>(undefined);
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (window['YT'] && window['YT'].Player && !player) {
      const videoPlayer = new window['YT'].Player('video-player', {
        videoId: 'PiHLJ2SzSG4',
        playerVars: {
          playersinline: 1,
          autoplay: 0,
          controls: 1,
        },
        events: {
          onReady: onReady,
          onError: e => {
            console.log('video error', e);
          },
        },
      });
      setPlayer(videoPlayer);
    }
  }, []);

  window['onYouTubeIframeAPIReady'] = () => {
    console.log('onYouTubeIframeAPIReady');
    if (window['YT'] && window['YT'].Player && !player) {
      const videoPlayer = new window['YT'].Player('video-player', {
        videoId: 'PiHLJ2SzSG4',
        playerVars: {
          playersinline: 1,
          autoplay: 0,
          controls: 1,
        },
        events: {
          onReady: onReady,
          onError: e => {
            console.log('video error', e);
          },
        },
      });
      setPlayer(videoPlayer);
    }
  };

  const onReady = useCallback(() => {
    console.log('video ready');
    console.log('videoPlayer', player);
  }, [player]);

  const onPlayVideo = () => {
    if (player && player.playVideo) {
      player.playVideo();
    }
  };

  return (
    <LandingVideoSectionWrapper>
      <div className="video-section-title">
        WATCH AND IN 2 MINUTES YOU&apos;LL KNOW HOW YOU CAN MAKE MONEY PLAYING
        GAMES ON BIGA ARCADE!
      </div>
      <LandingVideoWrapper>
        <LandingIframeWrapper>
          <LandingPreIframe id="video-player"></LandingPreIframe>
          {/* <LandingIframe
            id="landing-video"
            display={player ? 'hidden' : 'visible'}
            src="https://www.youtube.com/embed/ZEA83_DAuLM?start=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></LandingIframe> */}
        </LandingIframeWrapper>
        <LandingVideoPlayWrapper>
          <LandingVideoPlayButton
            src={playButtonImg}
            alt="play-arrow"
            onClick={onPlayVideo}
          />
          <LandingVideoPlayLinkButton onClick={onPlayVideo}>
            Play now
          </LandingVideoPlayLinkButton>
        </LandingVideoPlayWrapper>
      </LandingVideoWrapper>
    </LandingVideoSectionWrapper>
  );
};
