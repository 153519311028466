import gamePadMediumImg from 'assets/vectors/game-pad-medium.svg';
import usersNetImg from 'assets/vectors/users-net.svg';

import { LandingJoinWaitListButton } from './JoinWaitListButton';
import {
  LandingWaitListContent,
  LandingWaitListContentBottomImg,
  LandingWaitListContentWrapper,
  LandingWaitListSectionWrapper,
  LandingWaitListTitle,
  LandingWaitListUserNetImg,
} from './styled';

export const JoinWaitListSection = () => {
  return (
    <LandingWaitListSectionWrapper>
      <LandingWaitListTitle>
        <div>
          PUBLIC ALPHA IS NOW LIVE! EXPERIENCE FIRST-HAND HOW OUR ARCADE FEELS
          AND START PLAYING SOME OF OUR IN-HOUSE GAMES.
        </div>
      </LandingWaitListTitle>
      <LandingWaitListContentWrapper>
        <LandingWaitListUserNetImg src={usersNetImg} alt="users-net" />
        <LandingWaitListContent>
          <div>
            We will be running multiple scoring models and revenue simulations.
            This allows gamers to identify games that align with their skill
            sets and determine where to invest their time to generate their own
            revenue.
          </div>
          <LandingWaitListContentBottomImg
            src={gamePadMediumImg}
            alt="game-pad-medium"
          />
          <LandingJoinWaitListButton />
          <div className="wait-list-content-underlined">
            Play any game in the arcade, capture any level, or make any
            leaderboard and start earning today!
          </div>
        </LandingWaitListContent>
      </LandingWaitListContentWrapper>
    </LandingWaitListSectionWrapper>
  );
};
