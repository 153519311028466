import { PopupButton } from '@typeform/embed-react';
import { Collapse } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
`;

export const TextBIGA = styled(Text)`
  color: #0a3885;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-decoration-line: underline;
  cursor: pointer;
  display: inline-block;
`;

export const TextBIGAMail = styled(Link)`
  color: #0a3885;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  text-decoration-line: underline;
  cursor: pointer;
  display: inline-block;
  font-style: normal;
  font-family: Neue Haas Grotesk Display Pro;

  &:hover,
  &:focus,
  &:active {
    color: #0a3885;
    text-decoration-line: underline;
  }
`;

export const TextBIGAButton = styled(PopupButton)`
  color: #0a3885;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  font-style: normal;
  display: inline-block;
  text-decoration-line: underline;
  font-family: Neue Haas Grotesk Display Pro;

  &:hover,
  &:focus,
  &:active {
    color: #0a3885;
    text-decoration-line: underline;
  }

  padding: 0;
  border: none;
  cursor: pointer;
  background: transparent;
`;

export const SupportWrapper = styled.div`
  margin: 24px;
  background-color: #fff;
`;

export const SupportContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  max-height: calc(100vh);

  @media (max-width: 620px) {
    flex-direction: column;
    max-height: unset;
  }
`;

export const FirstBlock = styled.div`
  display: flex;
  max-width: 100%;
  padding: 0px 40px;
  flex-direction: column;
  gap: 24px;
  margin-top: 24px;
  width: 448px;

  @media (max-width: 1270px) {
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const FirstBlockItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-right: 16px;
`;

export const FirstBlockItemTitle = styled(Text)`
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;

  @media (max-width: 769px) {
    text-align: center;
  }
`;

export const FirstBlockItemDecription = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;

  @media (max-width: 769px) {
    text-align: center;
  }
`;

export const SecondBlock = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 769px) {
    display: none;
  }
`;

export const ThirdBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 24px;
  max-width: 100%;
  width: 802px;

  @media (max-width: 2000px) and (min-width: 1600px) {
    width: 100%;
  }
`;

export const ThirdBlockHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0px 40px;

  @media (max-width: 769px) {
    text-align: center;
  }
`;

export const ThirdBlockHeaderTitle = styled(Text)`
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  font-family: Changeling Neo;
  color: var(--brand-primary, #0a3885);
`;

export const ThirdBlockHeaderDecription = styled(Text)`
  color: #000;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  align-self: stretch;
  opacity: 0.8;
`;

export const ThirdBlockBody = styled.div`
  overflow: scroll;
  padding: 0px 25px;
  &::-webkit-scrollbar {
    display: none;
  }

  & > .ant-collapse-borderless {
    background-color: #fff;
  }

  &
    > .ant-collapse-icon-position-end
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    top: 10px;
  }
`;

export const CollapseWrapper = styled(Collapse)``;

export const CollapseHeader = styled(Text)`
  color: #000;
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
`;

export const CollapseContent = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  opacity: 0.8;
`;
