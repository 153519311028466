import { Collapse } from 'antd';
import arrowIcon from 'assets/vectors/logo-support-arrow.svg';
import { PitRouter } from 'constants/routers';
import useEnableCashier from 'hooks/cashier/useEnableCashier';
import { Link } from 'react-router-dom';
import HomepageTemplate from 'template/Homepage';

import {
  CollapseContent,
  CollapseHeader,
  CollapseWrapper,
  FirstBlock,
  FirstBlockItem,
  FirstBlockItemDecription,
  FirstBlockItemTitle,
  SecondBlock,
  SupportContentWrapper,
  SupportWrapper,
  TextBIGAButton,
  TextBIGAMail,
  ThirdBlock,
  ThirdBlockBody,
  ThirdBlockHeader,
  ThirdBlockHeaderDecription,
  ThirdBlockHeaderTitle,
} from './styled';

const { Panel } = Collapse;

export const SupportPage = () => {
  const isEnableCashier = useEnableCashier();
  const panels = [
    {
      key: '1',
      header: <CollapseHeader>What are BIGA Credits?</CollapseHeader>,
      content: (
        <CollapseContent>
          BIGA Credits are the official virtual currency used to access and
          enjoy our games on our Arcade platform. To play our games, you will
          need to acquire BIGA Credits. They can be purchased through our
          cashier using USDT, USDC, or ETH, or through our trusted third-party
          payment provider, Gemini.
        </CollapseContent>
      ),
    },
    {
      key: '2',
      header: <CollapseHeader>How much is one BIGA credit?</CollapseHeader>,
      content: (
        <CollapseContent>
          Each BIGA Credit holds a value of 0.10 USD.
        </CollapseContent>
      ),
    },
    {
      key: '3',
      header: <CollapseHeader>How do I purchase BIGA Credits?</CollapseHeader>,
      content: (
        <CollapseContent>
          To purchase BIGA Credits, simply visit our{' '}
          {isEnableCashier ? (
            <Link to={PitRouter.CASHIER}>cashier page</Link>
          ) : (
            'cashier page'
          )}
          . On this page, you will find various payment options available.
          Choose your preferred payment method and follow the instructions to
          complete the purchase of BIGA Credits.
        </CollapseContent>
      ),
    },
    {
      key: '4',
      header: (
        <CollapseHeader>How do I make money playing BIGA?</CollapseHeader>
      ),
      content: (
        <CollapseContent>
          To earn income playing BIGA games, you need to achieve and maintain
          level ownership in the games while other gamers attempt to compete and
          dethrone you. When you are the Level Master, all revenue generated
          from the plays encountered for that specific level or game goes
          directly into your account. You have the flexibility to utilize these
          earnings to conquer more levels, enhance your gaming experience, or
          even withdraw the funds into USD or USDT.
        </CollapseContent>
      ),
    },
    {
      key: '5',
      header: (
        <CollapseHeader>
          I don&apos;t have a crypto wallet, can I still purchase BIGA?
        </CollapseHeader>
      ),
      content: (
        <CollapseContent>
          Yes, you can! You can purchase BIGA Credits using one of our selected
          payment gateways that accept cash in USD. These gateways include, but
          may not be limited to in the future, Stripe, PayPal, and Apple Pay.
        </CollapseContent>
      ),
    },
    {
      key: '6',
      header: <CollapseHeader>Can I play for Free?</CollapseHeader>,
      content: (
        <CollapseContent>
          Yes, you can! We offer test levels in games that are free of charge,
          allowing you to familiarize yourself with the games. However, if you
          want to be eligible to earn, you must play levels with a minimum stake
          of one BIGA credit. To earn, you either need to reach the leaderboard
          or pass a level and maintain your position until someone surpasses or
          beats your score.
        </CollapseContent>
      ),
    },
    {
      key: '7',
      header: <CollapseHeader>What is a level master?</CollapseHeader>,
      content: (
        <CollapseContent>
          A Level Master is the prestigious title given to the owner of the
          highest score or the most recent gamer who passes that level in a
          specific game. Becoming a Level Master comes with exclusive benefits.
          The Level Master not only earns recognition and respect but also has
          the opportunity to generate INCOME from the revenue generated by that
          particular game. They continue to earn from the game until another
          skilled gamer surpasses their score and takes over the title as the
          new Level Master.
        </CollapseContent>
      ),
    },
    {
      key: '8',
      header: (
        <CollapseHeader>
          Is every game the same BIGA credit amount to play?
        </CollapseHeader>
      ),
      content: (
        <CollapseContent>
          No, each game has its own unique pricing structure. <br />
          <br />
          In certain games, the price may vary depending on the level or stage
          you wish to access. Some levels may require higher skill levels and
          confidence to attempt, making them more challenging. As the difficulty
          and demand increase, so does the credit requirement for those specific
          levels. This also means that the Level Master who achieves the highest
          score or mastery in those levels earns a higher revenue value.
        </CollapseContent>
      ),
    },
    {
      key: '9',
      header: <CollapseHeader>Is this Gambling?</CollapseHeader>,
      content: (
        <CollapseContent>
          No, our platform is not classified as gambling. <br />
          <br />
          Our games are skill-based rather than relying solely on luck. Becoming
          a Level Master requires dedication, effort, and honing your gaming
          skills. It&apos;s the mastery of gameplay and strategy that determines
          success, rather than chance or random outcomes. Therefore, our
          platform is not deemed as gambling.
        </CollapseContent>
      ),
    },
    {
      key: '10',
      header: (
        <CollapseHeader>
          How do I withdraw my BIGA credits to USD?
        </CollapseHeader>
      ),
      content: (
        <CollapseContent>
          You can withdraw your BIGA Credits directly via our cashier using
          selected payment gateways, including but not limited to Stripe,
          PayPal, and Apple Pay. For gamers interested in cryptocurrency, you
          also have the option to withdraw in USD stable coins such as USDT and
          USDC, if you prefer withdrawing via crypto rails.
        </CollapseContent>
      ),
    },
    {
      key: '11',
      header: (
        <CollapseHeader>I have a partnership opportunity?</CollapseHeader>
      ),
      content: (
        <CollapseContent>
          That&apos;s great to hear! We are always open to collaborating with
          projects that share our goal of providing gamers with the opportunity
          to earn income through playing games. To discuss partnership
          opportunities, please send us a message at partnerships@bigarcade.org.
          We will carefully review your proposal and respond if we find it to be
          a great fit for our platform.
        </CollapseContent>
      ),
    },
    {
      key: '12',
      header: <CollapseHeader>Can I play your games on Mobile?</CollapseHeader>,
      content: (
        <CollapseContent>
          Currently, all our games are designed for desktop users and accessible
          via mobile web browsers. However, we highly recommend playing our
          games on a desktop rather than a mobile device. This is because some
          of our games may require keyboard input or have gameplay mechanics
          better suited for desktop play. Having access to a keyboard enhances
          the gaming experience and ensures optimal gameplay.
        </CollapseContent>
      ),
    },
    {
      key: '13',
      header: (
        <CollapseHeader>
          Can I feature my Game on the BIGA arcade?
        </CollapseHeader>
      ),
      content: (
        <CollapseContent>
          Absolutely! We are interested in partnering with third-party
          developers or studios who want to launch or upload their games on BIGA
          Arcade. By featuring your game on our platform, you will have the
          opportunity to earn royalties based on the plays your game receives.
          This provides a revenue stream without the need for intrusive
          advertising placements that can be annoying for gamers. We do have
          specific requirements that your game must meet, but we are also
          flexible and willing to work with the right game to ensure a
          successful collaboration. To explore this opportunity further, please
          fill in the form provided at [insert form link]. Once we receive your
          submission, we will carefully evaluate whether we are a great match
          for each other.
        </CollapseContent>
      ),
    },
    {
      key: '14',
      header: (
        <CollapseHeader>How do you prevent cheating in games?</CollapseHeader>
      ),
      content: (
        <CollapseContent>
          At BIGA, we take cheating very seriously, and we have strict measures
          in place to maintain fair gameplay. Any gamer found attempting to
          cheat will face immediate termination from our platform. We also
          employ the latest anti-hacking software tools to detect and prevent
          cheating attempts. Our team is continuously updating and improving our
          games to stay ahead of any potential loopholes that cheaters may
          exploit. <br />
          <br />
          By maintaining a strong stance against cheating and implementing
          robust security measures, we strive to create a fair and enjoyable
          gaming environment for all our players.
        </CollapseContent>
      ),
    },
    {
      key: '15',
      header: <CollapseHeader>Who is your partner Gemini?</CollapseHeader>,
      content: (
        <CollapseContent>
          Gemini is a well-known cryptocurrency exchange and digital asset
          custodian. It was established in 2013 by Cameron and Tyler Winklevoss,
          and it operates under the regulatory oversight of the New York State
          Department of Financial Services. <br />
          <br />
          We have partnered with Gemini to provide a seamless on and off-ramp
          for gamers who may not have a cryptocurrency wallet or prefer not to
          pay using cryptocurrency. This partnership allows our users to
          conveniently and securely convert their funds between fiat currency
          (such as USD) and cryptocurrencies, facilitating easy transactions
          within our platform.
        </CollapseContent>
      ),
    },
    {
      key: '16',
      header: (
        <CollapseHeader>Do you do any Collabs with streamers?</CollapseHeader>
      ),
      content: (
        <CollapseContent>
          Yes, we do collaborate with streamers! If you are a streamer who has a
          strong relationship with your viewers, regardless of your size or
          following, we encourage you to reach out to us at
          partnerships@bigarcade.org. We are always looking for streamers who
          align with our goals and vision. We will carefully evaluate the
          synergy between our platform and your content and get back to you if
          we see a potential collaboration opportunity.
        </CollapseContent>
      ),
    },
    {
      key: '17',
      header: (
        <CollapseHeader>How often do you upload NEW games?</CollapseHeader>
      ),
      content: (
        <CollapseContent>
          At BIGA, our aim is to provide a diverse selection of games to our
          users. We have set a goal to offer 100 games on the BIGA platform
          within 24 months. To achieve this, we are actively working on
          releasing new games or multiple games every two weeks from the launch
          of the BIGA platform. <br />
          <br />
          We are dedicated to including various genres, including FPS, puzzle,
          racing, adventure, and more. We welcome game submissions from
          developers who believe their games would be a good fit for our arcade.
          If you have a game you&apos;d like to feature on our platform, please
          send us a message!
        </CollapseContent>
      ),
    },
    {
      key: '18',
      header: (
        <CollapseHeader>
          What is the Public Alpha for BIGA Arcade?
        </CollapseHeader>
      ),
      content: (
        <CollapseContent>
          The Public Alpha is a period before our official launch when we open
          the arcade to users who sign up early. This gives them an opportunity
          to familiarize themselves with the BIGA Arcade. During the Public
          Alpha, each user will receive 10,000 BIGA credits as a simulation to
          trial the arcade and see the potential earnings. Please note, these
          credits are not convertible to cash and are only provided for the
          testing period.
        </CollapseContent>
      ),
    },
  ];
  return (
    <HomepageTemplate>
      <SupportWrapper>
        <SupportContentWrapper>
          <FirstBlock>
            <FirstBlockItem>
              <FirstBlockItemTitle>Customer Support</FirstBlockItemTitle>
              <FirstBlockItemDecription>
                Can&apos;t find what you&apos;re looking for? If your query
                hasn&apos;t been addressed in the FAQs above, we&apos;re here to
                help. Please feel free to email us at{' '}
                <TextBIGAMail to="mailto:hello@bigarcade.org">
                  hello@bigarcade.org
                </TextBIGAMail>
                . Our dedicated team is always ready to provide you with the
                assistance you need
              </FirstBlockItemDecription>
            </FirstBlockItem>
            <FirstBlockItem>
              <FirstBlockItemTitle>Partnership Inquiry</FirstBlockItemTitle>
              <FirstBlockItemDecription>
                We are always looking for partners with whom we can create
                synergy and provide value to BIGA and our users. Please email us
                at{' '}
                <TextBIGAMail to="mailto:partners@bigarcade.org">
                  partners@bigarcade.org.
                </TextBIGAMail>
              </FirstBlockItemDecription>
            </FirstBlockItem>
            <FirstBlockItem>
              <FirstBlockItemTitle>Game Developers</FirstBlockItemTitle>
              <FirstBlockItemDecription>
                Want to build a game on BIGA? Game developers and studios have
                the opportunity to build or integrate their existing games onto
                our platform if they follow our requirements and are approved.
                If interested, please fill out this form{' '}
                <TextBIGAButton id="XbXfic2M">CLICK HERE</TextBIGAButton>.
              </FirstBlockItemDecription>
            </FirstBlockItem>
          </FirstBlock>
          <SecondBlock />
          <ThirdBlock>
            <ThirdBlockHeader>
              <ThirdBlockHeaderTitle>
                Frequently Asked Questions
              </ThirdBlockHeaderTitle>
              <ThirdBlockHeaderDecription>
                Most common questions users have about BIGA are answered below.
                If you do not find what you are looking for, please contact us
                at hello@bigarcade.org.
              </ThirdBlockHeaderDecription>
            </ThirdBlockHeader>
            <ThirdBlockBody>
              <CollapseWrapper
                defaultActiveKey={['1']}
                expandIconPosition="end"
                bordered={false}
                expandIcon={({ isActive }) => (
                  <img
                    src={arrowIcon}
                    style={{
                      transform: isActive ? 'rotate(0deg)' : 'rotate(180deg)',
                    }}
                  />
                )}
              >
                {panels.map(panel => (
                  <Panel key={panel.key} header={panel.header}>
                    {panel.content}
                  </Panel>
                ))}
              </CollapseWrapper>
            </ThirdBlockBody>
          </ThirdBlock>
        </SupportContentWrapper>
      </SupportWrapper>
    </HomepageTemplate>
  );
};
