export const convertScoringModelToDisplayType = (
  scoringModel: string,
): string => {
  switch (scoringModel) {
    case 'LevelPass':
      return 'Level ownership';
    case 'LevelPassHighestScore':
      return 'Highest score level ownership';
    case 'LevelPassLowestTime':
      return 'Fastest time level ownership';
    case 'HighestReplaceScore':
      return 'Highest Score';
    case 'HighestIncreaseScore':
      return 'Total Score';
    case 'LowestScore':
      return 'Lowest Score';
    case 'LowestTime':
      return 'Fastest Time';
    default:
      return 'Unknown Game Type';
  }
};
