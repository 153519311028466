import { Modal } from 'antd';
import styled from 'styled-components';

export const Text = styled.div`
  color: #fff;
  font-family: Neue Haas Grotesk Display Pro;
  font-style: normal;
  line-height: normal;
`;

export const ModalWrapper = styled(Modal)`
  & > .ant-modal-content {
    background-color: transparent;
    box-shadow: none;
    .ant-modal-body {
      padding: 0;
    }
  }
`;

export const ModalLevelMasterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const CloseWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  user-select: none;
`;

export const CloseIcon = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const BodyModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 684px; */
  width: 100%;
  background-color: #fff;
`;

export const BodyModalInfoWrapper = styled.div`
  display: flex;
  padding: 32px 0px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background: #0a3885;
`;

export const ModalInfoWrapper = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 24px;
  font-weight: 600;
`;

export const ModalInfoUpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
`;

export const ModalInfoUpValue = styled(Text)`
  font-size: 32px;
  font-weight: 700;
`;

export const ModalInfoUpTitle = styled(Text)`
  font-size: 14px;
  font-weight: 500;
`;

export const ModalInfoDownWrapper = styled(Text)`
  display: flex;
  padding: 8px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid #fff;

  font-size: 14px;
  font-weight: 500;
`;

export const BodyModalContentWrapper = styled.div<{
  isEmpty?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin: 24px 40px 34px 40px;

  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  max-height: 100%;
  height: ${props => (props.isEmpty ? '100%' : '400px')};
`;

export const ModalContentItemWrapper = styled.div`
  display: flex;
  /* width: 604px; */
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

export const ModalContentItemRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContentItemRightTitle = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.6;
`;

export const ContentItemRightValue = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 600;
  text-align: right;
`;

export const ModalContentItemLeftWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ContentItemLeftImgWrapper = styled.img`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 121px;
  max-width: 100%;
  height: 89px;
  object-fit: cover;
`;

export const ContentItemLeftDetailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContentItemLeftTitle = styled(Text)`
  color: #000;
  font-size: 16px;
  font-weight: 500;
`;

export const ContentItemLeftValue = styled(Text)`
  color: #000;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.6;
`;
