import gamePadSmallImg from 'assets/vectors/game-pad-small.svg';
import { GaActions, GaCategories, GaLabels } from 'constants/enum/GaEvent';
import { PitRouter } from 'constants/routers';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

import { LandingWaitListJoinButtonWrapper } from './styled';

export const LandingJoinWaitListButton = () => {
  const navigate = useNavigate();
  const handleRouterPublicAlphaButton = () => {
    ReactGA.event({
      category: GaCategories.GENERAL,
      action: GaActions.ACTION_PUBLIC_ALPHA,
      label: GaLabels.LABEL_PUBLIC_ALPHA,
    });
    navigate(PitRouter.REGISTER);
  };

  return (
    <LandingWaitListJoinButtonWrapper
      id="seTt8WvJ"
      onClick={() => handleRouterPublicAlphaButton()}
    >
      <div>
        <img src={gamePadSmallImg} alt="game-pad-small" />
        <span>Play now</span>
      </div>
    </LandingWaitListJoinButtonWrapper>
  );
};
