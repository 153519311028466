import {
  CaretRightOutlined,
  HolderOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Popover, Row, Tooltip } from 'antd';
import StatusButton from 'components/Button/StatusButton';
import { ThemedModal } from 'components/Modals/styled';
import { PlayerRoles } from 'constants/enum/PlayerRole';
import { useGetGameListForRole } from 'hooks/games/useGetGameListForRole';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ApplicationModal } from 'redux/slices/application';
import { useToggleModal } from 'redux/slices/application/hook';
import { setGameId } from 'redux/slices/detail';
import { useAppSelector } from 'redux/store';
import { changeOrderGame, setGameActiveStatus } from 'services/api';

import {
  LeaderboardTable,
  PopoverContentBodyWrapper,
  PopoverContentButtonWrapper,
  PopoverContentHeaderWrapper,
  PopoverContentInput,
  PopoverContentWrapper,
  StyledPagination,
} from './styled';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const RowDrags = ({ children, ...props }: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, child => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <Tooltip title="Drag to sort">
                <HolderOutlined
                  className="DragHandle"
                  ref={setActivatorNodeRef}
                  style={{ touchAction: 'none', cursor: 'move' }}
                  {...listeners}
                />
              </Tooltip>
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export const AllGamesTable = ({
  refresh,
  onRefresh,
  activeButton,
  searchQuery,
}: {
  refresh: boolean;
  onRefresh: () => void;
  activeButton: string;
  searchQuery?: any;
}) => {
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState<Record<string, boolean>>({});
  const detailsToggle = useToggleModal(ApplicationModal.GAME_DETAILS);
  const [popoverVisible, setPopoverVisible] = useState({});
  const [confirmVisible, setConfirmVisible] = useState<boolean>(false);
  const [confirmIsActive, setConfirmIsActive] = useState<boolean>(false);
  const [confirmGameId, setConfirmGameId] = useState<string>();

  const { playerInfo } = useAppSelector(state => state.player);

  const [params, setParams] = useState({
    pageSize: 6,
    pageIndex: currentPage,
    showStatistic: true,
  });

  const [paramsSearch] = useState({
    pageSize: 100,
    pageIndex: currentPage,
    showStatistic: true,
  });

  const { listGame: listGameSearch } = useGetGameListForRole(
    paramsSearch,
    refresh,
  );

  const {
    listGame,
    total,
    loading: gameListLoading,
  } = useGetGameListForRole(params, refresh);

  const [dataSource, setDataSource] = useState([]);
  useEffect(() => {
    if (listGame) {
      setDataSource(
        listGame.map((item, index) => ({
          ...item,
          key: item.gameId,
          index,
        })),
      );
    }
  }, [listGame]);

  const preventCharacter = e => {
    if (
      e.key === '.' ||
      e.key === '-' ||
      e.key === '+' ||
      e.key === 'e' ||
      e.key === 'E'
    ) {
      e.preventDefault();
    }
  };

  const handlePopoverVisibleChange = (gameId, visible) => {
    setPopoverVisible(prevState => ({
      ...prevState,
      [gameId]: visible,
    }));
  };

  const handleCancelClick = gameId => {
    setPopoverVisible(prevState => ({
      ...prevState,
      [gameId]: false,
    }));
  };

  const handleGameStatus = async (gameId, isActive: boolean) => {
    setConfirmIsActive(isActive);
    setConfirmGameId(gameId);
    setConfirmVisible(true);
    setLoading(prev => ({
      ...prev,
      [gameId]: true,
    }));
  };

  const renderButton = (isActive, record) => {
    const recordLoading = loading[record.gameId];
    if (!isActive) {
      return (
        <StatusButton
          action="accept"
          onClick={() => handleGameStatus(record.gameId, true)}
          loading={recordLoading}
          disabled={false}
        >
          Activate
        </StatusButton>
      );
    } else {
      return (
        <StatusButton
          action="inactive"
          onClick={() => handleGameStatus(record.gameId, false)}
          loading={recordLoading}
          disabled={false}
        >
          Deactivate
        </StatusButton>
      );
    }
  };

  const openGameDetails = id => {
    dispatch(setGameId(id));
    detailsToggle();
  };

  const renderDetails = record => {
    return (
      <div
        onClick={() => openGameDetails(record)}
        style={{ cursor: 'pointer' }}
      >
        <CaretRightOutlined />
      </div>
    );
  };

  const filteredData = useMemo(() => {
    if (!searchQuery) {
      return dataSource;
    }

    return listGameSearch.filter(
      game =>
        game.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        game.gameId.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }, [dataSource, searchQuery, listGameSearch]);

  const handleChangeOrder = async (gameId, orderNumber) => {
    const data = {
      orders: orderNumber,
    };

    try {
      const res = await changeOrderGame(gameId, data);
      if (res?.success) {
        toast.success('Reorder game success!!');
      } else {
        toast.error(res?.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      onRefresh();
    }
  };

  const Content = ({ record, onClose, onChangeOrder }) => {
    const [orderNumber, setOrderNumber] = useState('');

    const handleConfirmClick = () => {
      const order = Number(orderNumber);
      if (!isNaN(order)) {
        onChangeOrder(record.gameId, order);
      }
      onClose();
    };

    return (
      <PopoverContentWrapper>
        <PopoverContentHeaderWrapper>
          Reorder {record.name}
        </PopoverContentHeaderWrapper>
        <PopoverContentBodyWrapper>
          <PopoverContentInput
            type="number"
            min={1}
            placeholder="Select order"
            allowClear
            size="large"
            onKeyDown={preventCharacter}
            value={orderNumber}
            onChange={e => setOrderNumber(e.target.value)}
          />
          <PopoverContentButtonWrapper>
            <Button className="btn-cancel" onClick={onClose}>
              Cancel
            </Button>
            <Button
              className="btn-confirm"
              onClick={handleConfirmClick}
              disabled={orderNumber === ''}
            >
              Confirm
            </Button>
          </PopoverContentButtonWrapper>
        </PopoverContentBodyWrapper>
      </PopoverContentWrapper>
    );
  };

  const columns: Array<{
    title?: string;
    dataIndex?: string;
    key?: string;
    render?: (value: any, record: any) => React.ReactNode;
  }> = [
    ...(activeButton === 'customize'
      ? [
          {
            key: 'select',
            render: record => (
              <Popover
                content={
                  <Content
                    record={record}
                    onClose={() => handleCancelClick(record.gameId)}
                    onChangeOrder={handleChangeOrder}
                  />
                }
                trigger="click"
                visible={popoverVisible[record.gameId]}
                onVisibleChange={visible =>
                  handlePopoverVisibleChange(record.gameId, visible)
                }
                placement="left"
              >
                <Tooltip title="Manual reorder">
                  <PlusCircleOutlined />
                </Tooltip>
              </Popover>
            ),
          },
        ]
      : []),
    ...(activeButton === 'customize'
      ? [
          {
            key: 'sort',
          },
        ]
      : []),
    ...(activeButton === 'customize'
      ? [
          {
            title: 'No.',
            dataIndex: 'orders',
            key: 'orders',
          },
        ]
      : []),
    {
      title: 'Id',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Title',
      dataIndex: 'name',
      key: 'name',
      render: (name, record) => {
        return name || `Game ${record.gameId}`;
      },
    },
    {
      title: 'Token',
      dataIndex: 'tokenInfo',
      key: 'tokenInfo',
      render: value => value.symbol,
    },
    {
      title: 'Players',
      dataIndex: 'totalPlayers',
      key: 'totalPlayers',
    },
    {
      title:
        playerInfo &&
        (playerInfo?.roles.includes(PlayerRoles.SuperAdmin) ||
          playerInfo?.roles.includes(PlayerRoles.Admin))
          ? 'Action'
          : 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive, record) => {
        return playerInfo &&
          (playerInfo?.roles.includes(PlayerRoles.SuperAdmin) ||
            playerInfo?.roles.includes(PlayerRoles.Admin))
          ? renderButton(isActive, record)
          : isActive
          ? 'Active'
          : 'Inactive';
      },
    },
  ];

  columns.push({
    title: 'Detail',
    dataIndex: 'gameId',
    key: 'gameId',
    render: gameId => {
      return renderDetails(gameId);
    },
  });

  const handleConfirmOk = async () => {
    setConfirmVisible(false);

    const response = await setGameActiveStatus(confirmGameId, {
      isActive: confirmIsActive,
    });
    if (response?.success) {
      toast.success('Update game active status success!!');
      onRefresh();
    } else toast.error(response?.message);

    onRefresh();

    setLoading(prev => ({
      ...prev,
      [confirmGameId]: false,
    }));
    setConfirmGameId('');
    setConfirmIsActive(null);
  };

  const onDragEnd = async (event: DragEndEvent, onRefresh: () => void) => {
    const { active, over } = event;
    if (active.id !== over?.id) {
      const activeIndex = dataSource.findIndex(i => i.key === active.id);
      const overIndex = listGameSearch.findIndex(i => i.gameId === over?.id);
      const reorderedDataSource = arrayMove(dataSource, activeIndex, overIndex);
      setDataSource(reorderedDataSource);
      const draggedGame = reorderedDataSource.find(i => i.key === active.id);
      try {
        const res = await changeOrderGame(draggedGame.key, {
          orders: overIndex + 1,
        });
        if (res?.success) {
          toast.success('Reorder game success!!');
        } else {
          toast.error(res?.message);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        onRefresh();
      }
    }
  };

  return (
    <>
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={event => onDragEnd(event, onRefresh)}
      >
        <SortableContext
          items={dataSource.map(i => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <LeaderboardTable
            components={{
              body: {
                row: RowDrags,
              },
            }}
            dataSource={filteredData?.map(item => ({
              ...item,
              key: item.gameId,
            }))}
            columns={columns}
            pagination={false}
            loading={gameListLoading}
          />
        </SortableContext>
      </DndContext>

      <Row justify="center" className="mb-5">
        {total > 6 && (
          <StyledPagination
            className="mt-4"
            pageSize={6}
            current={currentPage}
            onChange={page => {
              setCurrentPage(page);
              setParams({
                ...params,
                pageIndex: page,
              });
            }}
            total={total}
            showSizeChanger={false}
          />
        )}
      </Row>
      <ThemedModal
        visible={confirmVisible}
        onOk={handleConfirmOk}
        onCancel={() => {
          setConfirmVisible(false);
          setLoading(prev => ({
            ...prev,
            [confirmGameId]: false,
          }));
          setConfirmGameId('');
          setConfirmIsActive(null);
        }}
        okText={confirmIsActive ? 'Activate' : 'Deactivate'}
        cancelText="No"
        width={400}
        style={{ marginTop: '50px' }}
      >
        <p>
          Do you want to {confirmIsActive ? 'activate' : 'deactivate'} this
          game?
        </p>
      </ThemedModal>
    </>
  );
};
